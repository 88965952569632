import { APP_INITIALIZER, NgModule } from '@angular/core';
import { FeaturesModule } from "./features/features.module";
import { SharedModule } from "./shared/shared.module";
import { CoreModule } from "./core/core.module";
import { AppComponent } from './app.component';
import { AngularBaseModule} from '@systems/base';
import { AppRoutingModule } from './app-routing.module';
import { SysbizConfigLoaderService } from './core/sysbiz/config-loader.service';

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    AngularBaseModule,
    FeaturesModule,
    SharedModule,
    CoreModule.forRoot(),
    AppRoutingModule
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializerFactory,
      deps: [SysbizConfigLoaderService],
      multi: true
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

export function appInitializerFactory(configsLoaderService: SysbizConfigLoaderService) {
  return () => configsLoaderService.load();
}