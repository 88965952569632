import { Pipe, PipeTransform, Inject, LOCALE_ID } from '@angular/core';
import { DatePipe } from '@angular/common';

@Pipe({
  name: 'utcToLocalTime'
})
export class CustomTimePipe implements PipeTransform {

  constructor(
    @Inject(LOCALE_ID) private locale: string // Inject the locale
  ) {}

  transform(utcDate: string | Date): string {
    if (!utcDate) return '';

    // Convert the UTC date-time string to a Date object if it's a string
    const date = typeof utcDate === 'string' ? new Date(utcDate) : utcDate;

    // Create a new DatePipe instance with the current locale
    const datePipe = new DatePipe(this.locale);

    // Format the date as 'HH:mm' (24-hour format)
    return datePipe.transform(date, 'HH:mm', this.locale);
  }
}