import { Injectable } from '@angular/core';
import { DocumentDto as ConservationDocumentDto } from './conservation/document.service';
import { DocumentDto as PostboxDocumentDto } from './archive/document.service';
import { NumberCompletenessCheckDto } from './number-completeness-check.service';
import { DocumentDto } from '../document-signer/document.service';
import { TemplateDto } from '../document-signer/template.service';

@Injectable({
  providedIn: 'root'
})
export class ValueMapper {
  mapNumberCompletenessCheck(source: any, dest: NumberCompletenessCheckDto) {
    return this.assignValues(dest, source);
  }

  constructor() { }

  public mapDocumentConservation(source: any, dest: ConservationDocumentDto): boolean {
    return this.assignValues(dest, source);
  }

  public mapDocumentPostbox(source: any, dest: PostboxDocumentDto): boolean {
    return this.assignValues(dest, source);
  }

  public mapDocument(source: any, dest: DocumentDto): boolean {
    return this.assignValues(dest, source);
  }

  public mapTemplate(source: any, dest: TemplateDto): boolean {
    return this.assignValues(dest, source);
  }

  private assignValues(dest: any, source: any): boolean {
    if (!source || !source.guid || !dest) {
      return false;
    } else if (source.guid === dest.guid) {

      Object.assign(dest, source);

      return true;
    } else {
      return false;
    }
  }
}
