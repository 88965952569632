import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'empty'
})
export class EmptyPipe implements PipeTransform {

  transform(value: any, alternative?: string): string {
    if (value === undefined || value === null || value === '' || value !== value || value.length == 0)
      return alternative ? alternative : '';
    else
      return value;
  }

}
