import { Component } from '@angular/core';

@Component({
  selector: 'app-confirm-run-number-completeness',
  templateUrl: './confirm-run-number-completeness.component.html',
  styleUrls: ['./confirm-run-number-completeness.component.scss']
})
export class ConfirmRunNumberCompletenessComponent {

}
