import { Component, Input, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { BasePageComponent, MonitoringService, PagedResultDto } from '@systems/base';
import { NumberCompletenessCheckHistoryDto } from 'src/app/core/sysbiz/number-completeness-check.service';


@Component({
  selector: 'app-child-list-number-completeness',
  templateUrl: './child-list-number-completeness.component.html',
  styleUrls: ['./child-list-number-completeness.component.scss']
})
export class ChildListNumberCompletenessComponent extends BasePageComponent implements OnInit {
  
  @Input() entries: NumberCompletenessCheckHistoryDto;
  public result: PagedResultDto<string>;


  constructor(
    modal: NgbModal,
    translationService: TranslateService,
    monitoringService: MonitoringService) {
    super(modal, monitoringService, translationService);
  }
  
  ngOnInit(): void {
    this.result = {} as PagedResultDto<string>;
    this.result.pageSize = 15;
    this.raiseRefresh();
  }

  raiseRefresh() {
    this.result = {
      currentPage: this.result.currentPage || this.result.currentPage == 0 ? this.result.currentPage : 1,

      pageCount: this.result.pageSize ? Math.ceil(this.entries.missingDocumentNumbers.length / this.result.pageSize) : 1,

      pageSize: this.result.pageSize || this.result.pageSize == 0 ? this.result.pageSize : 15,

      results: this.entries.missingDocumentNumbers.slice((this.result.currentPage ? this.result.currentPage : 1) * (this.result.pageSize ? this.result.pageSize : 15) - (this.result.pageSize ? this.result.pageSize : 15), this.result.pageSize ? (this.result.currentPage ? this.result.currentPage : 1) * this.result.pageSize : this.entries.missingDocumentNumbers.length),

      rowCount: this.entries.missingDocumentNumbers.length
    };
  }


}
