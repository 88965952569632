// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css?family=Ubuntu);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sidebar.hidden {
  width: 38px !important;
  transition: width 0.3s;
}
.sidebar.hidden ::ng-deep .label, .sidebar.hidden ::ng-deep .nav-item-label {
  display: none;
}
.sidebar.hidden ::ng-deep .btn-block {
  padding: 1rem 0.5rem;
  transition: padding 0.3s;
}
.sidebar.hidden ::ng-deep .nav-item {
  border: none;
}

.sidebar::-webkit-scrollbar {
  display: none;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/components/navigation-sidebar/navigation-sidebar.component.scss"],"names":[],"mappings":"AAEA;EACI,sBAAA;EAEA,sBAAA;AAAJ;AAIQ;EAEI,aAAA;AAHZ;AAMQ;EACI,oBAAA;EAEA,wBAAA;AAJZ;AAOQ;EACI,YAAA;AALZ;;AAUA;EACI,aAAA;AAPJ","sourcesContent":["@import './node_modules/@systems/base/assets/style/variables.scss';\r\n\r\n.sidebar.hidden {\r\n    width: $sibebar-collapsed-width !important;\r\n    -webkit-transition: width 0.3s;\r\n    transition: width 0.3s;\r\n\r\n    ::ng-deep {\r\n\r\n        & .label,\r\n        & .nav-item-label {\r\n            display: none;\r\n        }\r\n\r\n        & .btn-block {\r\n            padding: 1rem 0.5rem;\r\n            -webkit-transition: padding 0.3s;\r\n            transition: padding 0.3s;\r\n        }\r\n\r\n        .nav-item {\r\n            border: none;\r\n        }\r\n    }\r\n\r\n}\r\n.sidebar::-webkit-scrollbar{\r\n    display: none;\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
