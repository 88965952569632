import { Injectable } from '@angular/core';
import { Observable } from "rxjs";
import { Request, ObservableRequest } from './request.model';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from '../auth';

@Injectable({
  providedIn: 'root'
})
export class RequestService {

  private requests: Object = {};

  constructor(private translate: TranslateService, private authService: AuthService) { }

  /**
   * Adds a new request with an identifier. An already existing request
   * with the name will be unsubscribed and overwritten.
   * @param name Request identifier
   * @param observable$ Request
   */
  public addRequest<T>(name: string, observable$: Observable<T>): ObservableRequest<T> {
    let request$ = new ObservableRequest(observable$, this.translate, this.authService);

    // Unsubscribe existing requests
    if (typeof this.requests[name] != 'undefined')
      this.requests[name].unsubscribeAll()

    // Set new request
    this.requests[name] = request$;
    return request$;
  }

  /**
   * Get the request with the given identifier. If it doesn't exist a
   * new, empty Request will be returned.
   * @param name Request identifier
   */
  public getRequest(name: string): Request {
    return this.requests[name] ? this.requests[name] : new ObservableRequest(new Observable(), this.translate, this.authService);
  }

  /**
   * True if there are pending requests
   */
  public isPending(): boolean {
    for (let i in this.requests) {
      if (this.requests[i].isPending)
        return true;
    }
    return false;
  }

  /**
   * Unsubscribe from all requests
   */
  public unsubscribeAll() {
    for (let i in this.requests) {
      let request = this.requests[i];
      request.unsubscribeAll();
    }
  }
}
