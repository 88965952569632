import { Component } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-confirmation',
  templateUrl: './confirmation.component.html',
  styleUrls: ['./confirmation.component.scss']
})
export class ConfirmationComponent {

  constructor(
    private modal: NgbModal
  ) { }

  public dismiss(): void {
    // this.modal.close()
  }

  public close(): void {
    // this.modal.close(null)
  }
}
