// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css?family=Ubuntu);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `h1 {
  color: black;
}`, "",{"version":3,"sources":["webpack://./src/app/features/login/password-expired/password-expired.component.scss"],"names":[],"mappings":"AAEA;EACI,YAAA;AAAJ","sourcesContent":["@import './node_modules/@systems/base/assets/style/variables.scss';\r\n\r\nh1 {\r\n    color: invert($background-color);\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
