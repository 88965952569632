import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { formatCurrency, getCurrencySymbol } from '@angular/common';

@Pipe({
  name: 'customCurrency',
  pure: false
})
export class CustomCurrencyPipe implements PipeTransform {

  private value: string;

  constructor(
    private translate: TranslateService
  ) { }

  transform(value: any, currencyCode?: string, display?: 'code' | 'symbol' | 'symbol-narrow' | string, digitsInfo?: string, locale?: string, showZeroValue: boolean = true): string | null {
    // Perform transformation only if a value is set
    if (value != null) {
      const fcLocale = locale ? locale : this.translate.currentLang.substr(0, 2);
      const fcCurrencyCode = currencyCode ? currencyCode : 'EUR';
      const fcDigitsInfo = digitsInfo ? digitsInfo : '0.2';

      let fcCurrency;
      switch (display) {
        case 'code':
          fcCurrency = fcCurrencyCode;
          break;
        case 'symbol':
          fcCurrency = getCurrencySymbol(fcCurrencyCode, 'wide', fcLocale);
          break;
        case 'symbol-narrow':
        default:
          fcCurrency = getCurrencySymbol(fcCurrencyCode, 'narrow', fcLocale);
      }

      this.value = formatCurrency(value, fcLocale, fcCurrency, fcCurrencyCode, fcDigitsInfo);
      return this.value;
    } else
      return null;
  }

}
