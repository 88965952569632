import { Component, OnInit, ElementRef, ViewChild, HostListener } from '@angular/core';
import { RequestService } from 'src/app/core/request/request.service';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from 'src/app/core';
import { Partner } from 'src/app/config/partner/_default';
import { SysbizAuthService } from 'src/app/core/sysbiz/sysbiz-auth.service';
import { Observable, of } from 'rxjs';
import { debounceTime, map, distinctUntilChanged, switchMap, catchError, filter } from 'rxjs/operators';
import { SidebarToggleService } from '../navigation-sidebar/sidebar-toggle.service';
import { Router } from '@angular/router';
import { CompanyDto, CompanyService, CompanySortingDto } from 'src/app/core/customer/customer-client/company.service';
import { PagedResultDto } from '@systems/base';
import { SortDirectionDto } from 'src/app/core/customer/customer-client/sort-direction';


@Component({
  selector: 'app-navigation-main',
  templateUrl: './navigation-main.component.html',
  styleUrls: ['./navigation-main.component.scss']
})
export class NavigationMainComponent implements OnInit {

  public selectedCompanyDisplay: string = '';
  private selectedCompanyGuid: string = null;
  public companies: PagedResultDto<CompanyDto>;
  readonly Partner = Partner;
  public companyName: String;
  public showNavbar = false;
  public showUsermenu = false;
  public languages: Array<string>;
  public hasOnlyOneCompany: boolean = false;
  public checkPermission: boolean = false;
  public dropdownEnabled: boolean = false;
  public authToken: string;
  public isMenuCollapsed: boolean = true;
  public isSmallScreen = false;
  public isHovered = false;

  public get selectedCompany() {
    return this.selectedCompanyGuid;
  }

  public set selectedCompany(newValue: string) {
    this.selectedCompanyGuid = newValue;
    this.changeCompany(newValue);
  }

  @ViewChild('searchField') searchField: ElementRef;

  constructor(
    public requests: RequestService,
    public translate: TranslateService,
    public authService: AuthService,
    private companyService: CompanyService,
    private sysBizAuthService: SysbizAuthService,
    public sidebar: SidebarToggleService,
    public router: Router
  ) {

  }

  public getCurrentHelpRoute(): string {
    let routeParts = this.router.url.split(/[/\?]/);
    return routeParts.slice(0, 2).join("/") + "/help";
  }


  toggle() {
    this.isMenuCollapsed = !this.isMenuCollapsed;
    this.sidebar.toggle()
  }

  ngOnInit() {
    this.isMenuCollapsed = this.sidebar.isMenuCollapsed;
    this.getLanguages();
    this.translate.onLangChange.subscribe(() => this.getLanguages());
    this.isSmallScreen = window.innerWidth <= 1850; // Change the breakpoint as needed


    this.authService.subscribeUser().subscribe(x => {
      if (this.authService.User) {
        this.authToken = encodeURIComponent(this.sysBizAuthService.Token);

        this.CheckUserRole(this.authService.User.Roles);
        if (this.authService.User.SelectedCompanyUid) {
          this.selectedCompanyGuid = this.authService.User.SelectedCompanyUid;
          this.companyService.get(this.authService.User.SelectedCompanyUid).subscribe(x => {
            if (x.searchName === null || x.searchName === undefined || x.searchName === "") {
              this.selectedCompanyDisplay = x.datiAnagrafici.anagrafica.nome + " " + x.datiAnagrafici.anagrafica.cognome;
            } else {
              this.selectedCompanyDisplay = x.searchName;
            }
          }, () => {
            this.selectedCompanyGuid = null;
          });
        }
        this.companyService.getCompanyCount().subscribe(count => {
          if (count <= 15 && count != 1) {
            this.hasOnlyOneCompany = false;
            this.dropdownEnabled = true;
            this.companyService.listByFilters().subscribe(companies => {
              this.companies = companies;
            });
          } else if (count == 1) {
            this.hasOnlyOneCompany = true;
            this.dropdownEnabled = false;
            if (this.hasOnlyOneCompany && !this.authService.User.SelectedCompanyUid) {
              this.companyService.listByFilters().subscribe(companies => {
                this.changeCompany(companies[0].uid);
              });
            }
          }
        });
      }
    });
  }


  public search = (query$: Observable<string>) =>
    query$.pipe(
      filter(term => term.trim().length >= 3),
      distinctUntilChanged(),
      debounceTime(400),
      switchMap(
        term =>
          this.companyService.listByFilters(CompanySortingDto.SearchName, SortDirectionDto.ascending, term)
            .pipe(
              map(results => {
                //HACK: BUG 6783 show only the first 15 items
                return results.results.slice(0, 20);
              }),
              catchError(() => of([]))
            )
      )
    )

  /** Formatter for input */
  public inputFormatter = () => null;

  /** Formatter for output */
  public resultFormatter = (result: CompanyDto) => {
    var returnvaldefault = result.datiAnagrafici.idFiscaleIVA.idPaese + result.datiAnagrafici.idFiscaleIVA.idCodice + ' - ' + (result.searchName);
    return returnvaldefault;
  };

  public toggleNavbar(shown: boolean = null) {
    if (shown !== null) {
      this.showNavbar = shown;
    } else {
      this.showNavbar = !this.showNavbar;
      if (this.showUsermenu) {
        this.toggleUsermenu(false);
      }
    }
  }

  public toggleUsermenu(shown: boolean = null) {
    if (shown !== null) {
      this.showUsermenu = shown;
    } else {
      this.showUsermenu = !this.showUsermenu;
      if (this.showNavbar) {
        this.toggleNavbar(false);
      }
    }
  }

  public getLanguages(): void {
    this.languages = this.translate.getLangs().filter(lang => lang !== this.translate.currentLang);
  }

  public cancelSearch(): void {
    if (this.searchField.nativeElement.value == '' && this.selectedCompanyDisplay) {
      this.resetSearch();
    } else if (this.searchField.nativeElement.value != '') {
      this.searchField.nativeElement.value = this.selectedCompanyDisplay;
    }
  }

  public resetSearch(): void {
    this.selectedCompanyDisplay = '';
    this.changeCompany(null);
  }

  public changeLanguage(locale: string): void {
    this.translate.use(locale);

    localStorage.setItem('locale', locale);
    this.toggleUsermenu(false);
  }

  public logout(): void {
    this.authService.logout();
  }

  protected updateSelectedCompany(selectedCompany: CompanyDto) {

    this.selectedCompanyDisplay = null;
    if (this.authService.User && this.authService.User.SelectedCompanyUid !== selectedCompany.guid) {
      this.changeCompany(selectedCompany.guid);
    }
    this.selectedCompanyDisplay = selectedCompany.searchName;
  }

  private changeCompany(guid: string) {

    this.authService.User.SelectedCompanyUid = guid;

    const callSave$ = this.sysBizAuthService.updateSelectedCompany(guid);
    this.requests.addRequest('Update-Usersettings', callSave$).subscribe(() => { });
  }

  private CheckUserRole(rolearr: string[]): void {
    //check userrole area2 exists in the user role
    for (var i = 0; i < rolearr.length; i++) {
      if (rolearr[i] == "area2") {
        this.checkPermission = true
      }
    }
  }

  public get username(): string {
    return this.authService.User?.FullName;
  }
  public updateSearchText(target: EventTarget | null) {
    if (target === null) {
      return;
    }
    this.selectedCompanyDisplay = (target as HTMLInputElement).value;
  }

  // Add this HostListener function to listen for window resize events
  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.isSmallScreen = window.innerWidth <= 1850; // Change the breakpoint as needed
  }
}
