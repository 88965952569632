import { Component, Input, SimpleChanges, OnChanges, OnInit } from '@angular/core';
import { FatturaElettronica } from 'src/app/features/invoices/components/invoice/fattura-elettronica.class.generated';
import { ReplaySubject } from 'rxjs';
import { environment } from 'src/environments/environment';

export enum QualityStatus {
  Delaying = 13,
  Validating = 11,
  Invalid = 7,
  Mandatory = 5,
  Valid = 3,
  Empty = 2,
  None = undefined
}

@Component({
  selector: 'app-quality-status',
  templateUrl: './quality-status.component.html',
  styleUrls: ['./quality-status.component.scss']
})
export class QualityStatusComponent implements OnInit, OnChanges {

  @Input() private objects: Array<FatturaElettronica>;
  @Input() public hide: Array<QualityStatus>;
  @Input() public isMandatoryArray: boolean = false;
  @Input() private replace: Array<QualityStatus> = [];

  public delay: boolean = false;
  public status: QualityStatus;
  public status$: ReplaySubject<QualityStatus> = new ReplaySubject();
  public errors: Array<any> = [];

  constructor() {
    this.hide = [2, 3];
  }

  ngOnInit() {
    this.getStatus();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.objects) {
      this.objects = changes.objects.currentValue;
      this.objects.forEach((obj: FatturaElettronica) => {


        if (!Array.isArray(obj)) {
          // Subscribe on internal change (without debouncing)        
          obj.internalChange$.subscribe(() => {
            if (this.delay == false) {
              this.status$.next(QualityStatus.Validating);
            }
          });

          // Subscribe to completed change
          obj.change$.subscribe(() => {
            if (this.delay == false) {
              this.getStatus();
            }
          });

          // Subscribe to errors
          if (environment.production == false) {
            obj.error$.subscribe(() => console.error(obj.errors));
          }
        } else {
          if (obj.length <= 0) {
            this.getStatus();
          }
          obj.forEach((internalObj: FatturaElettronica) => {
            // Subscribe on internal change (without debouncing)        
            internalObj.internalChange$.subscribe(() => {
              if (this.delay == false) {
                this.status$.next(QualityStatus.Validating);
              }
            });

            // Subscribe to completed change
            internalObj.change$.subscribe(() => {
              if (this.delay == false) {
                this.getStatus();
              }
            });

            // Subscribe to errors
            if (environment.production == false) {
              internalObj.error$.subscribe(() => console.error(internalObj.errors));
            }
          });
        }

      });

      if (!this.objects.length && this.isMandatoryArray) {
        this.getStatus();
      }
    }
  }

  /**
   * Set quality status
   * @param status QualityStatus
   */
  public setStatus(status: QualityStatus) {
    this.status$.next(status);
    return status;
  }

  /**
   * Get quality status
   */
  public getStatus(): QualityStatus {
    let intermediateStatus: number = 1;     
    if (this.delay == true)
      intermediateStatus = intermediateStatus * QualityStatus.Delaying;
    else {
      if (this.objects.length > 0)
        for (let obj of this.objects) { 
          if (obj) {
            if (!Array.isArray(obj)) {
              if (obj.isSet()){
                if(obj.isValid()){
                  intermediateStatus = intermediateStatus * QualityStatus.Valid;
                  //Limit the number that intermediateStatus can reach because Angular can't handle extrem large numbers
                  if(intermediateStatus > 500000){ 
                    intermediateStatus= Math.sqrt(intermediateStatus);
                  }
                }
                else{
                  intermediateStatus = QualityStatus.Invalid;
                  break;
                }
              }
              else{
                intermediateStatus = intermediateStatus * (obj.isMandatory() ? QualityStatus.Mandatory : QualityStatus.Empty);
              }
            } else {
              let isValidArray = true;
              for (let internalObj of obj) {
                if (!internalObj.isValid()) {
                  isValidArray = false;
                  break;
                }
              }
              intermediateStatus = intermediateStatus * (isValidArray ? QualityStatus.Valid : QualityStatus.Invalid);
            }
          } else {
            intermediateStatus = QualityStatus.Empty;
          }
        }
      else
      {
        if(this.isMandatoryArray) {
          intermediateStatus = intermediateStatus * (false ? QualityStatus.Valid : QualityStatus.Invalid);
        } else {
          intermediateStatus = QualityStatus.Empty;
        }        
      }        
    }

    // Determine status
    if (intermediateStatus % QualityStatus.Delaying == 0)
      this.status = QualityStatus.Invalid;
    else if (intermediateStatus % QualityStatus.Validating == 0)
      this.status = QualityStatus.Invalid;
    else if (intermediateStatus % QualityStatus.Invalid == 0)
      this.status = QualityStatus.Invalid;
    else if (intermediateStatus % QualityStatus.Mandatory == 0)
      this.status = this.objects.length == 1 ? QualityStatus.Mandatory : QualityStatus.Invalid;
    else if (intermediateStatus % QualityStatus.Valid == 0)
      this.status = QualityStatus.Valid;
    else if (intermediateStatus % QualityStatus.Empty == 0)
      this.status = QualityStatus.Empty;

    // Hide (#5793)
    if (this.hide && this.hide.includes(this.status)) {
      this.status = QualityStatus.None;
    }

    // Replace (#5793)
    if (this.replace.includes(this.status))
      this.status = QualityStatus.None;

    // Emit status
    this.status$.next(this.status);

    // Return status
    return this.status;
  }
}
