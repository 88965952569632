import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { formatDate } from '@angular/common';
import { Language } from 'src/app/config/language';

@Pipe({
  name: 'customDate',
  pure: false
})
export class CustomDatePipe implements PipeTransform {

  private value: string;
  private format: string;
  private date: string;

  constructor(
    private translate: TranslateService
  ) { }

  /**
   * Return transformed value
   * @param value Date value
   * @param format Date format as in https://angular.io/api/common/DatePipe#pre-defined-format-options
   */
  transform(value: Date, customFormat?: string): any {
    // Perform transformation only if a value is set
    if (value) {
      // Date format
      let format = customFormat ? customFormat : Language.filter(l => l.locale.name == this.translate.currentLang).slice(0, 1).map(l => l.formats.date).toString();

      // Return already formatted date
      if (new Date(this.value) === value && this.format === format)
        return this.date;

      // Format date
      else {
        this.date = formatDate(value, format, this.translate.currentLang);
        return this.date;
      }
    } else
      return value;
  }

}
