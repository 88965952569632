import { Component, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { registerLocaleData } from '@angular/common';
import { ActivatedRoute, Router, ResolveEnd, ActivatedRouteSnapshot } from '@angular/router';
import { filter } from 'rxjs/operators';
import { Language } from './config/language';
import { LanguageConfig } from './core/interfaces/language-config';
import { SysbizAuthService } from './core/sysbiz/sysbiz-auth.service';
import { AuthService } from './core';
import {  MonitoringService } from '@systems/base';
import { Subscription } from 'rxjs';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnDestroy, OnInit {
  private locales: Array<string> = [];
  private tokens: Array<string> = [];
  private routerSubscription?: Subscription;

  constructor(
    private authSysBizService: SysbizAuthService,
    private activatedRoute: ActivatedRoute,
    private authService: AuthService,
    private monitoringService: MonitoringService,  
    private router: Router,
    public translate: TranslateService
  ) {

    // Handle languages
    this.handleLanguages();

  }

  ngOnInit() {      
    this.monitoringService.startMonitoring();

    this.routerSubscription = this.router.events
      .subscribe(event => {
        if (event instanceof ResolveEnd) {
          const activatedComponent = this.getActivatedComponent(event.state.root);
          if (activatedComponent) {
            this.monitoringService.logPageView(activatedComponent.name, event.urlAfterRedirects);
          }
        }
      });
    // Use language from query parameter
    this.activatedRoute.queryParams
    .pipe(
      filter(params => 'lang' in params || 'authtoken' in params)
    )
      .subscribe(params => {
        if (params['lang']) {
          const langIdx = this.tokens.indexOf(params['lang']);
          if (langIdx >= 0) {
            this.translate.use(this.locales[langIdx]);
          }
        }

        if (params['authtoken']) {
          //TODO: remove it in the final version

          const newToken = params['authtoken'];

          if (newToken !== this.authSysBizService.Token) {
            // it is an other token => cancel current user infos
            this.authService.resetCurrentUser();
          }

          this.authSysBizService.Token = newToken;
        }
      });
  }

  ngOnDestroy(): void {
    if (this.routerSubscription) {
      this.routerSubscription.unsubscribe();
      this.routerSubscription = undefined;
    }
  }
  private handleLanguages() {
    // Register locales & prepare needed attributes
    Language.map((l: LanguageConfig) => {
      this.tokens.push(l.id);
      this.locales.push(l.locale.name);
      registerLocaleData(l.locale.localeData, l.id, l.locale.localeExtraData);
    });

    // Language setup
    this.translate.addLangs(this.locales);

    // Use locale from local storage
    if (localStorage.getItem('locale') && this.locales.indexOf(localStorage.getItem('locale')) >= 0)
      this.translate.setDefaultLang(localStorage.getItem('locale'));

    // Use browser locales
    else if (navigator.language) {
      const browserLocaleIdx = this.locales.indexOf(navigator.language.substr(0, 2));

      // Use full browser locale
      if (this.locales.indexOf(navigator.language) >= 0)
        this.translate.setDefaultLang(navigator.language);

      // Use only language from browser locale
      else if (browserLocaleIdx >= 0)
        this.translate.setDefaultLang(this.tokens[browserLocaleIdx]);

      // Use first configured locale as default locale
      else
        this.translate.setDefaultLang(this.locales[0]);
    }

    // Use first configured locale as default locale
    else
      this.translate.setDefaultLang(this.locales[0]);

    this.translate.use(this.translate.defaultLang);

    // Use default language if it gets set
    this.translate.onDefaultLangChange.subscribe((event) => {
      this.translate.use(event.lang)
    });

    // Store selected language and update title on language change
    this.translate.onLangChange.subscribe(() => {
      localStorage.setItem('locale', this.translate.currentLang)
    });
  }

  private getActivatedComponent(snapshot: ActivatedRouteSnapshot): any {

    if (snapshot.firstChild) {
      return this.getActivatedComponent(snapshot.firstChild);
    }

    return snapshot.component;
  }
}
