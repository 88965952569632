import { Directive, ElementRef, HostListener, Renderer2, Input } from '@angular/core';

@Directive({
  selector: '[ocs]'
})
export class OcsDirective {

  constructor(
    private element: ElementRef,
    private renderer: Renderer2
  ) {
    this.renderer.addClass(this.element.nativeElement, 'ocs');
  }

  /** Select on single click */
  @HostListener('click') onclick() {
    this.select();
  }

  /** Select and copy into clipboard on double click */
  @HostListener('dblclick') ondblclick() {
    document.execCommand('copy');
  }

  /**
   * Select all content of the element
   */
  private select() {
    switch (this.element.nativeElement.tagName.toLowerCase()) {
      case 'input':
      case 'textarea':
        this.element.nativeElement.select()
        break;
      default:
        window.getSelection().selectAllChildren(this.element.nativeElement);
    }
  }

}
