import { Injectable } from '@angular/core';
import { AuthService } from '../..';

@Injectable({
  providedIn: 'root'
})
export class DocumentTypeHelper {

  constructor(
    private authService: AuthService
  ) { }

  /**
   * Returns the possible DocumentTypes (FATTURA ATTIVA, FATTURA PASSIVA)
   */
  public async getDocumentTypes(context: string): Promise<DocumentType[]> {
    let docTypes: DocumentType[] = new Array();

    if (await this.authService.rbac(['area1z'])) {
      docTypes.push({ id: 1, context: ['outgoing', 'upload'], shortName: "FATATT", name: "FATTURA ATTIVA" });
    }
    if (await this.authService.rbac(['area28z'])) {
      docTypes.push({ id: 2, context: ['incoming', 'upload'], shortName: "FATPAS", name: "FATTURA PASSIVA" });
    }
    if (await this.authService.rbac(['area28z'])) {
      docTypes.push({ id: 7, context: ['incoming'], shortName: "FATPAS_INT", name: "FATTURE D'INTERGRAZIONE" });
    }
    if (await this.authService.rbac(['areaNSO'])) {
      docTypes.push({ id: 3, context: ['incoming', 'outgoing', 'upload'], shortName: "ORDINEATT", name: "ORDINE ATTIVO" });
    }

    if (context !== undefined && context !== null)
      return docTypes.filter(d => d.context.includes(context));
    return docTypes;
  }

  public async getDocumentDefaultType(context: string): Promise<string> {
    let docType: string;
    if (await this.authService.rbac(['area1z', 'area28z'])) {
      docType = context === 'incoming' ? 'FATPAS' : 'FATATT';
    }

    if (await this.authService.rbac(['areaNSO'])) {
      if (context === 'incoming') {
        if (await this.authService.rbac(['area28z']) == false) {
          docType = 'ORDINEATT';
        }
      }
      if (context === 'outgoing') {
        if (await this.authService.rbac(['area1z']) == false) {
          docType = 'ORDINEATT';
        }
      }
    }


    return docType;
  }
}

export interface DocumentType {
  id: number,
  context: Array<string>,
  shortName: string,
  name: string
}