import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { MonitoringService, PagedResultDto } from '@systems/base';
import { Observable, debounceTime, distinctUntilChanged, filter, finalize, map, switchMap } from 'rxjs';
import { AuthService } from 'src/app/core/auth/auth.service';
import { CompanyService, CompanySortingDto } from 'src/app/core/customer/customer-client/company.service';
import { CustomerService, CustomerSortingDto } from 'src/app/core/customer/customer-client/customer.service';
import { PartnerService, PartnerSortingDto } from 'src/app/core/customer/customer-client/partner.service';
import { SortDirectionDto } from 'src/app/core/customer/customer-client/sort-direction';
import { UserService } from 'src/app/core/customer/customer-client/user.service';
import { CompanyGroupDto, SysbizAuthService, UserDto, WriteUserDto } from 'src/app/core/sysbiz/sysbiz-auth.service';
import { BaseEditAdapterComponent } from '../../base-edit-adapter-component';

@Component({
  selector: 'app-entity-search',
  templateUrl: './entity-search.component.html',
  styleUrls: ['./entity-search.component.scss']
})
export class EntitySearchComponent extends BaseEditAdapterComponent<UserDto, WriteUserDto, UserService> implements OnInit, OnDestroy {

  get dtoName(): string {
    return this.dto;
  }

  protected generateDefault(): WriteUserDto {
    return null;
  }

  @ViewChild('searchField') searchField: ElementRef;

  @Input() item: UserDto;
  @Input() dto: string = "company";
  @Input() required: boolean = true;


  @Input() isCompanyValid: boolean = false;


  public companyUserGroups: CompanyGroupDto[] = [];

  public updating = false;
  public saved = false;

  public entityList: Array<any>;
  public loadingEntities: boolean = false;

  public selectedEntityDisplay: string = '';
  private selectedEntityGuid: string = null;

  @Input() private preSelectedEntityGuid: string = '';

  public isSearching: boolean = false;

  @Output() onEntityChanged = new EventEmitter<any>();

  @Input() public validationActive;

  constructor(
    public partnerService: PartnerService,
    public customerService: CustomerService,
    public companyService: CompanyService,
    public userService: UserService,
    public sysbizAuthService: SysbizAuthService,
    modal: NgbModal,
    translationService: TranslateService,
    monitoringService: MonitoringService,
    public authService: AuthService) {
    super(userService, modal, monitoringService, translationService, authService);
  }
  ngOnDestroy(): void {
  }

  ngOnInit(): void {
    super.ngOnInit();

    if (this.dto == 'company') {
      this.loadCompany(this.preSelectedEntityGuid);
    }
  }


  public searchEntities = (query$: Observable<string>) =>
    query$.pipe(
      filter(term => term.trim().length >= 3),
      distinctUntilChanged(),
      debounceTime(400),
      switchMap((term) => {
        this.isSearching = true;
        this.onEntityChanged.emit(undefined);

        if (this.dto == "partner") {
          return this.partnerService.listByFilters(
            PartnerSortingDto.name,
            SortDirectionDto.ascending,
            term,
            "active",
            1,
            15,
          ).pipe(finalize(() => this.isSearching = false));
        }
        else if (this.dto == "customer") {
          return this.customerService.listByFilters(
            CustomerSortingDto.name,
            SortDirectionDto.ascending,
            term,
            null,
            "active",
            1,
            15
          ).pipe(finalize(() => this.isSearching = false));
        }
        else if (this.dto == "company") {
          return this.companyService.listByFilters(
            CompanySortingDto.SearchName,
            SortDirectionDto.ascending,
            term,
            true,
            1,
            15,
            true
          ).pipe(finalize(() => this.isSearching = false));
        }

      }),
      map((customers: PagedResultDto<any>) => customers.results),
    );

  public inputFormatter = () => null;

  public resultFormatter = (result: any) => {
    if (this.dto === "company") {
      return result.searchName + "(" + result.customer.name + ")";
    }
    else if (this.dto === "partner") {
      return result.name + " (Nr. " + result.number + ")";
    }
    else {
      return result.name + "(" + result.partner.name + ")";
    }
  };

  protected updateSelectedEntity(selectedEntity: any) {
    if (this.dto === "company") {
      this.selectedEntityDisplay = selectedEntity.searchName;
    }
    else {
      this.selectedEntityDisplay = selectedEntity.name;
    }
    this.changeEntity(selectedEntity);
  }
  private changeEntity(newEntity: any) {
    this.selectedEntityGuid = newEntity.guid
    this.onEntityChanged.emit(newEntity);
  }

  public updateSearchText(target: EventTarget | null) {
    if (target === null) {
      return;
    }
    this.selectedEntityDisplay = (target as HTMLInputElement).value;
  }

  public cancelSearch(): void {
    if (this.searchField.nativeElement.value == '' && this.selectedEntityDisplay) {
      this.resetSearch();
    } else if (this.searchField.nativeElement.value != '') {
      this.searchField.nativeElement.value = this.selectedEntityDisplay;
    }
  }

  public loadCompany(companyGuid?: string): void {
    if (companyGuid)
      this.selectedEntityGuid = companyGuid;
    else
      this.selectedEntityGuid = this.authService.User.SelectedCompanyUid;

    if (!this.selectedEntityGuid)
      return;

    this.companyService.get(this.selectedEntityGuid).subscribe(x => {
      this.changeEntity(x);
      this.updateSelectedEntity(x);
    });
  }

  public resetSearch(): void {
    this.searchField.nativeElement.value = '';
    this.selectedEntityDisplay = '';
    this.selectedEntityGuid = undefined;

    this.onEntityChanged.emit(this.selectedEntityGuid);
  }
}
