import { Component, HostListener, OnInit } from '@angular/core';
import { Location } from '@angular/common';

@Component({
  selector: 'app-number-completeness-sidebar',
  templateUrl: './number-completeness-sidebar.component.html',
  styleUrls: ['./number-completeness-sidebar.component.scss']
})
export class NumberCompletenessSidebarComponent implements OnInit {

  public isSmallScreen: boolean = false;

  constructor(private location: Location) {}

  ngOnInit(): void {
    this.isSmallScreen = window.innerWidth <= 992; 
  }

  public goBack() {
    this.location.back();
  }
  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.isSmallScreen = window.innerWidth <= 992; 
  }
}
