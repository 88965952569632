import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { APPINSIGHTS_CONFIG, ApplicationInsights, BaseAuthConfig, BASEAUTH_CONFIG, ConfigLoaderService, RestClientConfig } from '@systems/base';

@Injectable({
  providedIn: 'root'
})
export class SysbizConfigLoaderService extends ConfigLoaderService {

  constructor(private client: HttpClient) {
    super(client);
  }

  public override load() {
    return new Promise((resolve, reject) => {

      this.client.get('assets/config.json')
      .subscribe((envResponse: any) => {
        const ssoConfig = new BaseAuthConfig();
        Object.assign(ssoConfig, {
          redirectPage: envResponse.ssoRedirectPage
        });
        const archiveApiConfig = new RestClientConfig();
        Object.assign(archiveApiConfig, {
          domain: envResponse.api.archive.domain,
          port: envResponse.api.archive.port,
          protocol: envResponse.api.archive.protocol,
          pathPrefix: envResponse.api.archive.pathPrefix,
          version: envResponse.api.archive.version
        });
        const conservationApiConfig = new RestClientConfig();
        Object.assign(conservationApiConfig, {
          domain: envResponse.api.conservation.domain,
          port: envResponse.api.conservation.port,
          protocol: envResponse.api.conservation.protocol,
          pathPrefix: envResponse.api.conservation.pathPrefix,
          version: envResponse.api.conservation.version
        });
        const customerApiConfig = new RestClientConfig();
        Object.assign(customerApiConfig, {
          domain: envResponse.api.customer.domain,
          port: envResponse.api.customer.port,
          protocol: envResponse.api.customer.protocol,
          pathPrefix: envResponse.api.customer.pathPrefix,
          version: envResponse.api.customer.version
        });
        const postboxApiConfig = new RestClientConfig();
        Object.assign(postboxApiConfig, {
          domain: envResponse.api.postbox.domain,
          port: envResponse.api.postbox.port,
          protocol: envResponse.api.postbox.protocol,
          pathPrefix: envResponse.api.postbox.pathPrefix,
          version: envResponse.api.postbox.version
        });
        const documentSignerConfig = new RestClientConfig();
        Object.assign(documentSignerConfig, {
          domain: envResponse.api.documentSigner.domain,
          port: envResponse.api.documentSigner.port,
          protocol: envResponse.api.documentSigner.protocol,
          pathPrefix: envResponse.api.documentSigner.pathPrefix,
          version: envResponse.api.documentSigner.version
        });
        const bankingConfig = new RestClientConfig();
        Object.assign(bankingConfig, {
          domain: envResponse.api.banking.domain,
          port: envResponse.api.banking.port,
          protocol: envResponse.api.banking.protocol,
          pathPrefix: envResponse.api.banking.pathPrefix,
          version: envResponse.api.banking.version
        });
        const appInsightsConfig = new ApplicationInsights();
        Object.assign(appInsightsConfig, {
          instrumentationKey: envResponse.appInsights.instrumentationKey
        });


        this.loadBaseAuthManually(ssoConfig);
        ARCHIVEAPICLIENT_CONFIG = archiveApiConfig;
        CONSERVATIONAPICLIENT_CONFIG = conservationApiConfig;
        this.loadApplicationInsightsManually(appInsightsConfig);
        CUSTOMERAPICLIENT_CONFIG = customerApiConfig;
        POSTBOXAPICLIENT_CONFIG = postboxApiConfig;
        DOCUMENTSIGNER_CONFIG = documentSignerConfig;
        BANKING_CONFIG = bankingConfig;
        resolve(true);
      });
    });
  }
}


export let ARCHIVEAPICLIENT_CONFIG: RestClientConfig;
export let CONSERVATIONAPICLIENT_CONFIG: RestClientConfig;
export let CUSTOMERAPICLIENT_CONFIG: RestClientConfig;
export let POSTBOXAPICLIENT_CONFIG: RestClientConfig;
export let DOCUMENTSIGNER_CONFIG: RestClientConfig;
export let BANKING_CONFIG: RestClientConfig;

