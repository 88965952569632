// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.links {
  position: absolute;
  left: 0;
  margin-left: 0.75rem;
}

.z-3 {
  z-index: 3;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/footer/footer.component.scss"],"names":[],"mappings":"AAAA;EACI,kBAAA;EACA,OAAA;EACA,oBAAA;AACJ;;AAEA;EACI,UAAA;AACJ","sourcesContent":[".links {\r\n    position: absolute;\r\n    left: 0;\r\n    margin-left: 0.75rem;\r\n}\r\n\r\n.z-3 {\r\n    z-index: 3;\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
