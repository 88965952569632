import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';
import { Partner as CurrentPartner } from 'src/app/config/partner/_default';
import { Partner as Systems } from 'src/app/config/partner/systems';
import { Partner as Dev } from 'src/app/config/partner/_development';
import { Partner as Test } from 'src/app/config/partner/_test';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class SystemsPartnerGuardService implements CanActivate {

  private systemsStables: string[] = [Systems.id, Dev.id, Test.id];
  
  constructor(
    private authService: AuthService
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    if (this.isSystems)
      return true;
    else
      this.authService.redirectToLogin(state.url);
  }

  get isSystems(): boolean {
    return this.systemsStables.includes(CurrentPartner.id);
  }
}