import { Injectable, Inject } from '@angular/core';
import { SysbizConfigService, SysbizConfig } from './sysbiz-config.service';
import { HttpResponseBase } from '@angular/common/http';
import { Option } from 'src/app/features/invoices/components/invoice/select/select.component';

export const TOKEN_NAME: string = 'token';

@Injectable({
  providedIn: 'root'
})
export abstract class SysbizService {

  /**
   * Type of API
   */
  readonly api: string;

  /**
   * API version
   */
  readonly version: string = 'v3';

  constructor(
    @Inject(SysbizConfigService) protected config: SysbizConfig
  ) { }

  /**
   * Compose URL for API call
   * @param endpoint Path to API endpoint
   * @param parameters Optional query parameters
   */
  protected url(endpoint: string, parameters?: Object): string {
    // Assemble url
    let url: string = this.config.domain;

    // Optional: port
    if (this.config.port)
      url += `:${this.config.port}`;

    url += '/';

    // Optional: path prefix
    if (this.config.pathPrefix)
      url += `${this.config.pathPrefix}/`;

    // API type (archive, conservation, customer)
    if (this.api)
      url += `${this.api}/`;

    // Optional: path prefix
    if (this.version)
      url += `${this.version}/`;

    // Endpoint
    url += endpoint;

    // Add query parameters
    if (parameters) {
      let query = [];
      for (let key of Object.keys(parameters)) {
        let param = parameters[key];
        if (param.constructor == 'Array') {
          if (param.length == 1)
            query.push(key + '=' + param[0]);
          else
            for (let value of param)
              query.push(key + '[]=' + value);
        } else
          query.push(key + '=' + param);
      }
      url += '?' + query.join('&');
    }

    return url;
  }

  /**
   * Get current user token
   */
  public get Token(): string {
    return localStorage.getItem(TOKEN_NAME);
  }

  /**
   *Update the token
   * @param newToken new token to use
   */
  public set Token(token: string) {
    if (token)
      localStorage.setItem(TOKEN_NAME, token);
    else
      localStorage.removeItem(TOKEN_NAME);
  }

  protected getToken(): string {
    return localStorage.getItem(TOKEN_NAME);
  }

  /**
   * Update the token from the http responce
   * @param response api response
   */
  protected updateTokenFromResponse(response: HttpResponseBase) {
    let newToken = response.headers.get('authenticate');
    if (newToken)
      localStorage.setItem(TOKEN_NAME, newToken);
  }

  protected updateToken(newToken: string) {
    if (newToken)
      localStorage.setItem(TOKEN_NAME, newToken);
  }
}
