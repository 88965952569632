import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from '@systems/base';
import { map, Observable } from 'rxjs';
import { CUSTOMERAPICLIENT_CONFIG } from '../../sysbiz/config-loader.service';
import { RestApiClientAdapterService } from '../../sysbiz/rest-api-client-adapter.service';
import { CompanyGroupDto, GroupDto, UserDto, UserGroupDto, WriteUserDto } from '../../sysbiz/sysbiz-auth.service';
import { ApplicationDto } from './application.service';

@Injectable({
  providedIn: 'root'
})
export class UserService  extends RestApiClientAdapterService<UserDto, UserDto, UserDto> {

  constructor(
    protected http: HttpClient,
    authService: AuthService,
    protected translationService: TranslateService
  ) {
    super(CUSTOMERAPICLIENT_CONFIG, http, authService, 'partners');
  }

  public assignUser(users: Array<WriteUserDto>, relationGuid: string, url: string){
    return this.http.post<UserDto>(this.getUrl(url), users, {
      headers: {
        'authenticate': this.Token
      },
      observe: 'response',
      responseType: 'json',
    })
      .pipe(map(resp => {
        this.updateTokenFromResponse(resp);
        return resp.body!;
      }));
  }
  public removeUser(url: string){
    return this.http.delete<boolean>(this.getUrl(url), {
      headers: {
        'authenticate': this.Token
      },
      observe: 'response',
      responseType: 'json',
    })
      .pipe(map(resp => {
        this.updateTokenFromResponse(resp);
        return resp.body!;
      }));
  }

  public removeUserFromCompany(guid: string, companyUserGroups: Array<CompanyGroupDto>){
    return this.http.put<boolean>(this.getUrl(`companies/${guid}/users`), companyUserGroups, {
      headers: {
        'authenticate': this.Token
      },
      observe: 'response',
      responseType: 'json'
    })
      .pipe(map(resp => {
        this.updateTokenFromResponse(resp);
        return resp.body!;
      }));
  }
  public assignUsersToCompany(guid: string, users: Array<CompanyGroupDto>){
    return this.http.post<Array<CompanyGroupDto>>(this.getUrl(`companies/${guid}/users`), users, {
      headers: {
        'authenticate': this.Token
      },
      observe: 'response',
      responseType: 'json',
    })
      .pipe(map(resp => {
        this.updateTokenFromResponse(resp);
        return resp.body!;
      }));
  }
  public listCompanyUserGroups(companyGuid: string, userGuid: string){
    return this.http.get<Array<UserGroupDto>>(this.getUrl(`companies/${companyGuid}/users/${userGuid}`), { headers: { 'authenticate': this.Token }, observe: 'response'})
    .pipe(map(res => {
      this.updateTokenFromResponse(res);
      return res.body;
    }));
  }

  public listApplicationByUser(userGuid?: string): Observable<Array<ApplicationDto>> {
    return this.http.get<Array<ApplicationDto>>(this.getUrl(`users/${userGuid}/applications`),
      {
        responseType: 'json',
        headers: {
          authenticate: this.Token
        },
        observe: "response",
      }).pipe(map((resp: any) => {
        this.updateTokenFromResponse(resp);
        return resp.body;
      }));
  }


  public listUserLicenses(userGuid: string){
    return this.http.get<Array<string>>(this.getUrl(`users/licenses/${userGuid}`), { headers: { 'authenticate': this.Token }, observe: 'response'})
    .pipe(map(res => {
      this.updateTokenFromResponse(res);
      return res.body;
    }));
  }
  
}
