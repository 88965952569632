import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

// SysBiz
import { SysbizService } from './sysbiz.service';
import { SysbizConfig } from 'src/app/core/interfaces/sysbiz-config';
import { SysbizConfigService } from './sysbiz-config.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { SortDirectionDto } from '../customer/customer-client/sort-direction';
import { IDtoGuid, PagedResultDto } from '@systems/base';
import { ApplicationDto, WriteApplicationDto } from '../customer/customer-client/application.service';

export interface UserDto extends WriteUserDto, IDtoGuid{

}

export interface WriteUserDto{
  id?: number;
  uid?: string;
  title?: UserTitle;
  userName?: string;
  firstName?: string;
  lastName?: string;
  phoneNumber?: string;
  eMail?: string;
  applicationKey?: string;
  token?: string;
  userToken?: string;
  selectedCompany?: string;
  roles?: Array<string>;
  isActive?: boolean;
  taxNumber?: string;
  password?: string;
  isServiceAccount?: boolean;
  partnerGuid?: string;
  customerGuid?: string;
  companyGuid?: string;
  companyGroupIds?: Array<number>;
  language?: UserLanguage;
}

export interface ModifyUserRequestDto extends CreateUserRequestDto {
}

export interface CreateUserRequestDto {
  title?: UserTitle;
  userName?: string;
  name?: string;
  surname?: string;
  taxNumber?: string;
  phone?: string;
  isActive?: boolean;
  password?: string;
  partnerGuid?: string;
  customerGuid?: string;
  companyGuid?: string;
  companyGroupIds?: Array<number>;
  isServiceAccount?: boolean;
  language?: UserLanguage;
}

export interface UserListElementDto  extends IDtoGuid{
  id: number;
  uid: string;
  userName: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  isActive: boolean;
  taxNumber: string;
}

export interface GroupDto{
  groupname: string;
  oid: number;
  childGroups: Array<GroupDto>;
  isChecked?: boolean;
}

export interface CompanyGroupDto{
  userGuid: string;
  groupIds: Array<number>;
}

export interface UserGroupDto{
  userGuid: string;
  groupId: number;
}

export interface Token {
  access_token?: string;

  token_type?: string;

  expires_in?: string;

  expires_on?: string;

  refresh_token?: string;

  scope?: string;

}

export enum UserTitle {
  Company = "Company",
  Female = "Female",
  Male = "Male"
}

export enum UserLanguage {
  DE = "DE",
  IT = "IT",
  EN = "EN"
}

@Injectable({
  providedIn: 'root'
})
export class SysbizAuthService extends SysbizService {

  /**
   * Type of API
   */
  readonly api: string = 'auth';

  /**
   * API version
   */
  readonly version: string = 'v3';

  readonly endpoint: string = 'users';


  constructor(
    @Inject(SysbizConfigService) protected config: SysbizConfig,
    protected http: HttpClient
  ) {
    super(config);
  }

  /**
   * Authenticate the user in base of the username and password
   * @param user
   * @param password
   */
  public internalAuthenticate(username: string, password: string): Observable<Token> {

    const body = {
      userName: username,
      password: password,
      encrypted: false
    }

    return this.http.post<Token>(this.url('internalauthenticate'), body).pipe(map(res => {

      this.Token = res.access_token;

      return res;
    }));
  }


  /**
   * Reset the password
   * @param username
   * @param password
   * @param newPassword
   */
  public resetPassword(username: string, password: string, newPassword: string): Observable<boolean> {

    const body = {
      userName: username,
      password: password,
      newPassword: newPassword
    }

    return this.http.post<boolean>(this.url('resetPassword'), body).pipe(map(res => {
      return res;
    }));
  }

  /**
 * Request a new password for user
 * @param user
 */
   public requestNewPassword(username: string, password = '', sendMail = true): Observable<boolean> {
    var requestString = 'requestNewPassword?userName=' + username + "&password=" + password + "&sendMail=" + sendMail;
    return this.http.post<boolean>(this.url(requestString ), null).pipe(map(res => {
      return res;
    }));
  }

    /**
 * Request a new password for user
 * @param user
 */
     public generateRandomPassword(): Observable<string> {
      return this.http.get<string>(this.url('user/generateRandomPassword'), { headers: { 'authenticate': this.Token }, observe: 'response'})
      .pipe(map(res => {
        this.updateTokenFromResponse(res);
        return res.body;
      }));
    }

  /**
   * Get user information in base of the current token
   */
  public getAllUsers(
    sorting?: UserSortingDto,
    sortDirection?: SortDirectionDto,
    searchText?: string,
    pageNumber?: number,
    pageSize?: number
  ): Observable<PagedResultDto<UserListElementDto>> {
    let userToken = this.Token;

    let params = new HttpParams();

    if (sorting) {
      params = params.append('sorting', sorting as any);
    }
    if (sortDirection) {
      params = params.append('sortDirection', sortDirection as any);
    }
    if (searchText) {
      params = params.append('searchName', searchText as any);
    }
    if (pageNumber) {
      params = params.append('pageNumber', pageNumber as any);
    }
    if (pageSize) {
      params = params.append('pageSize', pageSize as any);
    }

    return this.http.get<PagedResultDto<UserListElementDto>>(this.url(`${this.endpoint}/list`), { headers: { 'authenticate': userToken }, observe: 'response', params })
      .pipe(map(resp => {
        this.updateTokenFromResponse(resp);
        return resp.body;
      }));
  }

  
  public UserDetails(guid: string): Observable<UserDto> {
    let userToken = this.Token;

    return this.http.get<UserDto>(this.url(`users/${guid}`), { headers: { 'authenticate': userToken }, observe: 'response'})
      .pipe(map(resp => {
        this.updateTokenFromResponse(resp);
        return resp.body;
      }));
  }


  public listApplications(): Observable<Array<ApplicationDto>> {
    let userToken = this.Token;

    return this.http.get<Array<ApplicationDto>>(this.url(`applications`), { headers: { 'authenticate': userToken }, observe: 'response'})
      .pipe(map(resp => {
        this.updateTokenFromResponse(resp);
        return resp.body;
      }));
  }

  public list(
    searchName?: string,
    pageNumber?: number,
    pageSize?: number
  ): Observable<PagedResultDto<UserListElementDto>> {
    let userToken = this.Token;

    let params = new HttpParams();
    
    if (pageNumber) {
      params = params.append('pageNumber', pageNumber as any);
    }
    if (pageSize) {
      params = params.append('pageSize', pageSize as any);
    }
    if (searchName) {
      params = params.append('searchName', searchName as any);
    }

    return this.http.get<PagedResultDto<UserListElementDto>>(this.url(`${this.endpoint}/list`), { headers: { 'authenticate': userToken }, observe: 'response', params })
      .pipe(map(resp => {
        this.updateTokenFromResponse(resp);
        return resp.body;
      }));
  }

  /**
   * Get user information in base of the current token
   */
  public getUser(): Observable<UserDto> {
    let userToken = this.Token;

    return this.http.get<UserDto>(this.url(`${this.endpoint}`), { headers: { 'authenticate': userToken }, observe: 'response' })
      .pipe(map(resp => {
        this.updateTokenFromResponse(resp);
        if (!resp.body.selectedCompany)
          resp.body.selectedCompany = null;
        return resp.body;
      }));
  }

  public listPartnerUsers(
    partnerGuid: string,
    sorting?: UserSortingDto,
    sortDirection?: SortDirectionDto,
    searchText?: string,
    pageNumber?: number,
    pageSize?: number) : Observable<PagedResultDto<UserListElementDto>>{
    let userToken = this.Token;

    let params = new HttpParams();

    if (partnerGuid) {
      params = params.append('partnerGuid', partnerGuid as any);
    }
    if (sorting) {
      params = params.append('sorting', sorting as any);
    }
    if (sortDirection) {
      params = params.append('sortDirection', sortDirection as any);
    }
    if (searchText) {
      params = params.append('searchName', searchText as any);
    }
    if (pageNumber) {
      params = params.append('pageNumber', pageNumber as any);
    }
    if (pageSize) {
      params = params.append('pageSize', pageSize as any);
    }

    return this.http.get<PagedResultDto<UserListElementDto>>(this.url('users/getByPartnerGuid'), { headers: { 'authenticate': userToken }, observe: 'response', params })
      .pipe(map(resp => {
        this.updateTokenFromResponse(resp);
        return resp.body;
      }));
  }

  public listCustomerUsers(
    customerGuid: string,
    sorting?: UserSortingDto,
    sortDirection?: SortDirectionDto,
    searchText?: string,
    pageNumber?: number,
    pageSize?: number) : Observable<PagedResultDto<UserListElementDto>>{
    let userToken = this.Token;

    let params = new HttpParams();
    if (customerGuid) {
      params = params.append('customerGuid', customerGuid as any);
    }
    if (sorting) {
      params = params.append('sorting', sorting as any);
    }
    if (sortDirection) {
      params = params.append('sortDirection', sortDirection as any);
    }
    if (searchText) {
      params = params.append('searchName', searchText as any);
    }
    if (pageNumber) {
      params = params.append('pageNumber', pageNumber as any);
    }
    if (pageSize) {
      params = params.append('pageSize', pageSize as any);
    }

    return this.http.get<PagedResultDto<UserListElementDto>>(this.url('users/getByCustomerGuid'), { headers: { 'authenticate': userToken }, observe: 'response', params })
      .pipe(map(resp => {
        this.updateTokenFromResponse(resp);
        return resp.body;
      }));
  }
  

  public listCustomerApplications(customerGuid: string) : Observable<PagedResultDto<ApplicationDto>>{
    let userToken = this.Token;

    return this.http.get<PagedResultDto<ApplicationDto>>(this.url(`applications/getByCustomerGuid/${customerGuid}`), { headers: { 'authenticate': userToken }, observe: 'response'})
      .pipe(map(resp => {
        this.updateTokenFromResponse(resp);
        return resp.body;
      }));
  }

  public listPartnerApplications(partnerGuid: string) : Observable<PagedResultDto<ApplicationDto>>{
    let userToken = this.Token;

    return this.http.get<PagedResultDto<ApplicationDto>>(this.url(`applications/getByPartnerGuid/${partnerGuid}`), { headers: { 'authenticate': userToken }, observe: 'response'})
      .pipe(map(resp => {
        this.updateTokenFromResponse(resp);
        return resp.body;
      }));
  }

  public listUserApplications(userId: number) : Observable<PagedResultDto<ApplicationDto>>{
    let userToken = this.Token;

    return this.http.get<PagedResultDto<ApplicationDto>>(this.url(`users/applications/${userId}`), { headers: { 'authenticate': userToken }, observe: 'response'})
      .pipe(map(resp => {
        this.updateTokenFromResponse(resp);
        return resp.body;
      }));
  }

  public listGroups(licensedModules: Array<string>) : Observable<Array<GroupDto>>{
    let userToken = this.Token;

    var queryString = licensedModules.join('&licenseModules=');
    queryString = '&licenseModules=' + queryString;
    return this.http.get<Array<GroupDto>>(this.url(`users/groups?${queryString}`), { headers: { 'authenticate': userToken }, observe: 'response'})
      .pipe(map(resp => {
        this.updateTokenFromResponse(resp);
        return resp.body;
      }));
  }
  public listUserGroups(guid: string) : Observable<Array<GroupDto>>{
    let userToken = this.Token;

    return this.http.get<Array<GroupDto>>(this.url(`users/${guid}/groups`), { headers: { 'authenticate': userToken }, observe: 'response'})
      .pipe(map(resp => {
        this.updateTokenFromResponse(resp);
        return resp.body;
      }));
  }

  public addApplicationToUser(userGuid: string, applicationIds: Array<number>) : Observable<boolean>{
    return this.http.post<ApplicationDto>(this.url(`users/${userGuid}/applications`), applicationIds,
      {
        responseType: 'json',
        headers: {
          authenticate: this.Token
        },
        observe: "response"
      }).pipe(
        map((resp: any) => {
        this.updateTokenFromResponse(resp);
        return resp.body;
      }));
  }

  public removeApplicationFromUser(userGuid: string, applicationId: number) : Observable<boolean>{
    return this.http.delete<ApplicationDto>(this.url(`users/${userGuid}/application/${applicationId}`),
      {
        responseType: 'json',
        headers: {
          authenticate: this.Token
        },
        observe: "response"
      }).pipe(
        map((resp: any) => {
        this.updateTokenFromResponse(resp);
        return resp.body;
      }));
  }

  public addGroupsToUser(userGroups: Array<UserGroupDto>) : Observable<boolean>{
    return this.http.post<boolean>(this.url(`users/groups`), userGroups,
      {
        responseType: 'json',
        headers: {
          authenticate: this.Token
        },
        observe: "response"
      }).pipe(
        map((resp: any) => {
        this.updateTokenFromResponse(resp);
        return resp.body;
      }));
  }

  public removeGroupsFromUser(userGroups: Array<UserGroupDto>) : Observable<boolean>{
    return this.http.put<boolean>(this.url(`users/groups`), userGroups,
      {
        responseType: 'json',
        headers: {
          authenticate: this.Token
        },
        observe: "response",
      }).pipe(
        map((resp: any) => {
        this.updateTokenFromResponse(resp);
        return resp.body;
      }));
  }


  public listCompanyUsers(
    companyGuid: string,
    sorting?: UserSortingDto,
    sortDirection?: SortDirectionDto,
    searchText?: string,
    pageNumber?: number,
    pageSize?: number) : Observable<PagedResultDto<UserListElementDto>>{
    let userToken = this.Token;

    let params = new HttpParams();
    if (companyGuid) {
      params = params.append('companyGuid', companyGuid as any);
    }
    if (sorting) {
      params = params.append('sorting', sorting as any);
    }
    if (sortDirection) {
      params = params.append('sortDirection', sortDirection as any);
    }
    if (searchText) {
      params = params.append('searchName', searchText as any);
    }
    if (pageNumber) {
      params = params.append('pageNumber', pageNumber as any);
    }
    if (pageSize) {
      params = params.append('pageSize', pageSize as any);
    }

    return this.http.get<PagedResultDto<UserListElementDto>>(this.url('users/getByCompanyGuid'), { headers: { 'authenticate': userToken }, observe: 'response', params })
      .pipe(map(resp => {
        this.updateTokenFromResponse(resp);
        return resp.body;
      }));
  }

  /**
   * Update the selected company uid
   */
  public updateSelectedCompany(selectedCompanyUid: string): Observable<object> {
    let userToken = this.Token;

    if (selectedCompanyUid)
      selectedCompanyUid = "\"" + selectedCompanyUid + "\"";

    return this.http.put(this.url('company'), selectedCompanyUid, { headers: { 'authenticate': userToken, 'Content-Type': 'application/json' }, observe: 'response' })
      .pipe(map(resp => {
        this.updateTokenFromResponse(resp);
        return resp.body;
      }));
  }

  /**
 * Authenticate the user in base of the username and password
 * @param guid
 * @param vat
 * @param docType
 */
  public internalTokenFile(guid: string, vat: string, docType: string): Observable<Token> {

    const body = {
      guid: guid,
      vat: vat,
      docType: docType
    }

    return this.http.post<Token>(this.url('internalTokenFileAuth'), body, { headers: { 'authenticate': this.Token, 'Content-Type': 'application/json' } }).pipe(map(res => {
      return res;
    }));
  }


  /**
   * Create a new User
   *
   * @param item item with the values to create
   */
  public create(item: UserDto): Observable<UserDto> {
    var userRequestDto = {
      userName: item.userName,
      title: item.title,
      language: item.language,
      name: item.firstName,
      surname: item.lastName,
      isActive: item.isActive,
      taxNumber: item.taxNumber,
      phone: item.phoneNumber,
      password: item.password,
      partnerGuid: item.partnerGuid,
      customerGuid: item.customerGuid,
      companyGuid: item.companyGuid,
      companyGroupIds: item.companyGroupIds,
      isServiceAccount: item.isServiceAccount
    } as CreateUserRequestDto;

    return this.http.post<UserDto>(this.url(this.endpoint), userRequestDto, {
      responseType: 'json',
      headers: {
        authenticate: this.Token
      },
      observe: "response"
    }).pipe(map(resp => {
      this.updateTokenFromResponse(resp);
      return resp.body;
    }));
  }

  /**
   * Update an item
   *
   * @param guid id of the item to update
   * @param item values of the item
   */
  public update(id: number, item: UserDto): Observable<UserDto> {
    var userRequestDto = {
      userName: item.userName,
      title: item.title,
      language: item.language,
      name: item.firstName,
      surname: item.lastName,
      isActive: item.isActive,
      taxNumber: item.taxNumber,
      phone: item.phoneNumber
    } as ModifyUserRequestDto;

    return this.http.put<UserDto>(this.url(`${this.endpoint}/${id}`), userRequestDto,
      {
        responseType: 'json',
        headers: {
          authenticate: this.Token
        },
        observe: "response"
      }).pipe(map((resp: any) => {
        this.updateTokenFromResponse(resp);
        return resp.body;
      }));
  }
}

export enum UserSortingDto {
  IsActive = "IsActive",
  UserName = "UserName",
  FirstName = "FirstName",
  LastName = "LastName",
  TaxNumber = "TaxNumber",
  PhoneNumber = "PhoneNumber"
}