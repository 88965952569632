import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { AuthService } from 'src/app/core/auth';

@Directive({
  selector: '[rbac]'
})
export class RbacDirective {

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private authService: AuthService
  ) { }

  @Input() set rbac(condition: Array<string>) {
    this.update(condition);
  }

  private async update(condition: Array<string>) {
    if (await this.authService.rbac(condition) && this.templateRef) {
      this.viewContainer.createEmbeddedView(this.templateRef);
    } else {
      this.viewContainer.clear();
    }
  }
}
