import { Pipe, PipeTransform } from '@angular/core';
import { AuthService } from 'src/app/core';

@Pipe({
  name: 'rbac'
})
export class RbacPipe implements PipeTransform {
  constructor(private authService: AuthService){}

  transform(value: Array<string>, args?: any): any {
    return this.authService.rbac(value);
  }

}
