import { OnInit, OnDestroy, NgZone, Directive } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BaseListComponent, IDtoGuid, IListApi, MonitoringService } from '@systems/base';
import { AuthService } from '../core';
import { HttpErrorResponse } from '@angular/common/http';

@Directive()
export abstract class BaseListAdapterComponent<
  SortingDto,
  Dto extends IDtoGuid,
  Service extends IListApi<Dto>>
  extends BaseListComponent<SortingDto, Dto, Service> implements OnInit, OnDestroy {

  constructor(
    service: Service,
    modal: NgbModal,
    monitoringService: MonitoringService,
    protected override translationService: TranslateService,
    router: Router,
    zone: NgZone,
    route: ActivatedRoute,
    protected authService: AuthService) {
    super(service, modal, monitoringService, translationService, router, zone, route);
  }

  override async raiseError(message: any) {
    if ((message.status == 401) || (message instanceof HttpErrorResponse && (message.error.defaultMessage === 'Token is expired' || message.error.title === 'Unauthorized')))
      this.authService.logout();

    super.raiseError(message);
  }
  protected override startRefreshIntervall() {
    //Do nothing to avoid endless loop on timeouts
  }
}
