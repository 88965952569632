import { OnInit, OnDestroy, Directive } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Location } from '@angular/common';
import { BaseDetailComponent, IDetailsApi, MonitoringService } from '@systems/base';
import { HttpErrorResponse } from '@angular/common/http';
import { AuthService } from '../core';

@Directive()
export abstract class BaseDetailAdapterComponent<
  DetailDto,
  Service extends IDetailsApi<DetailDto>>
  extends BaseDetailComponent<DetailDto, Service> implements OnInit, OnDestroy {

  constructor(
    service: Service,
    modal: NgbModal,
    monitoringService: MonitoringService,
    translationService: TranslateService,
    activatedRoute: ActivatedRoute,
    location: Location,
    protected authService: AuthService) {
    super(service, modal, monitoringService, translationService, activatedRoute, location);
  }

  override async raiseError(message: any) {
    if ((message.status == 401) || (message instanceof HttpErrorResponse && (message.error.defaultMessage === 'Token is expired' || message.error.title === 'Unauthorized')))
      this.authService.logout();

    super.raiseError(message);
  }
}