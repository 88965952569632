import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { AuthService, RequestService } from 'src/app/core';
import { ActivatedRoute, Router } from '@angular/router';
import { from } from 'rxjs';
import { RequestError } from 'src/app/core/request';
import { SysbizAuthService } from 'src/app/core/sysbiz/sysbiz-auth.service';

@Component({
  selector: 'app-password-expired',
  templateUrl: './password-expired.component.html',
  styleUrls: ['./password-expired.component.scss']
})
export class PasswordExpiredComponent implements OnInit {
  private returnUrl: string = '';
  private userName: string = '';
 
  public errorLoginMessage: string;
  public success: boolean = false;
  
  public validationActive = false;
  public oldPassword: string = '';
  public newPassword: string = '';
  public confirmedPassword: string = '';  
  public regexPassword = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@.~#\$%\^&\*\}\|\{\`_\]\\\[@\?\>=\<;:\/\.\-,\+\*\)\(\'\&%\"\ ])/;

  constructor(   
    private router: Router,
    private route: ActivatedRoute,
    private authService: AuthService,
    private sysbizAuthService: SysbizAuthService,
    private requests: RequestService) { }

  ngOnInit() {
      this.route.queryParams
        .subscribe(params => 
          {
            this.returnUrl = params['return'] || '';
            this.userName = params['userName'] || '';
          });      
  }

  public async resetPassword() {
    this.errorLoginMessage = null;
    let call$ = from(this.sysbizAuthService.resetPassword(this.userName, this.oldPassword, this.newPassword));
    this.requests.addRequest('reset', call$).subscribe(
      x => {
        
        this.authService.login(this.userName, this.newPassword)
        .then(x => {
          this.router.navigateByUrl(this.returnUrl);
          this.success = true;           
        })
        .catch(err =>  this.errorLoginMessage = err.translatedMessage);        
      },
      (err: RequestError) => 
      {      
          this.errorLoginMessage = err.translatedMessage;        
      }
    );
  }   

}
