import { Component, OnInit } from '@angular/core';
import { Partner as CurrentPartner } from 'src/app/config/partner/_default';
import { Partner as Systems } from 'src/app/config/partner/systems';
import { Partner as Dev } from 'src/app/config/partner/_development';
import { Partner as Test } from 'src/app/config/partner/_test';
import { SystemsPartnerGuardService } from 'src/app/core/auth/systems-partner-guard.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  get isSystems(): boolean {
    return this.systemsPartnerGuard.isSystems;
  }

  constructor(
    private systemsPartnerGuard: SystemsPartnerGuardService
  ) { }

  ngOnInit(): void {
  }

}
