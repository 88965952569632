import { Injectable } from '@angular/core';
import { RestApiClientAdapterService } from '../sysbiz/rest-api-client-adapter.service';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { AuthService } from '@systems/base';
import { TranslateService } from '@ngx-translate/core';
import { BANKING_CONFIG } from '../sysbiz/config-loader.service';
import { Observable, map } from 'rxjs';
import { UploadTransactionResultDto } from './transaction.service';

@Injectable({
  providedIn: 'root'
})
export class BankingCompanyService extends RestApiClientAdapterService<BankingCompanyDto, BankingCompanyDto, BankingCompanyDto> {

  constructor(
    protected http: HttpClient,
    authService: AuthService,
    protected translationService: TranslateService
  ) {
    super(BANKING_CONFIG, http, authService, 'companies');
  } 

  public details(companyGuid: string
  ): Observable<BankingCompanyDto> {

    return this.http.get<BankingCompanyDto>(this.getUrl(`companies/${companyGuid}`),
      {
        responseType: 'json',
        observe: 'response',
        headers: {
          authenticate: this.Token
        }
      }).pipe(map((resp: any) => {
        this.updateTokenFromResponse(resp);
        return resp.body;
      }));
  }
  public syncTransactions(companyGuid: string
  ): Observable<UploadTransactionResultDto> {
    return this.http.post<UploadTransactionResultDto>(this.getUrl(`companies/${companyGuid}/synchronize`),{},
      {
        responseType: 'json',
        observe: 'response',
        headers: {
          authenticate: this.Token
        }
      }).pipe(map((resp: any) => {
        this.updateTokenFromResponse(resp);
        return resp.body;
      }));
  }

  public startOnboarding(companyGuid: string, onboardingProcess: OnBoardingProcessDto
  ): Observable<BankingCompanyDto> {
    return this.http.post<BankingCompanyDto>(this.getUrl(`companies/${companyGuid}/onboarding`),onboardingProcess,
      {
        responseType: 'json',
        observe: 'response',
        headers: {
          authenticate: this.Token
        }
      }).pipe(map((resp: any) => {
        this.updateTokenFromResponse(resp);
        return resp.body;
      }));
  }
}
export interface BankingCompanyDto {
  companyGuid : string;
  externalUserCompanyCode?: string;
  externalUserId?: string;
  externalUserOnboardingId?: string;
  externalOnboardingState?: string;
  externalOnboardingUrl?: string;
}

export interface OnBoardingProcessDto {
  companyUserCode?: string;
  name?: string;
  surname?: string;
  contacts?: ContactDto;
  fiscalCode?: string;
  birthDate?: Date;
  birthPlace?: string;
  homeAddress?: AddressDto;
  currentAddress?: AddressDto;
  startSyncUtc?: Date;
}

export interface AddressDto {
  city?: string;
  country?: string;
  countryId?: number;
  postalCode?: string;
  province?: string;
  provinceId?: number;
  buildingNumber?: number;
  address?: string;
}

export interface ContactDto {
  mail?: string;
  mobile?: string;
}


