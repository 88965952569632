import { NgModule, ModuleWithProviders } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { CommonModule, DecimalPipe, DatePipe } from '@angular/common';
import { HttpClientModule, HttpBackend } from '@angular/common/http';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule, TranslateLoader, TranslateService, TranslatePipe } from '@ngx-translate/core';

// import { AppRoutingModule } from './app-routing/app-routing.module';
import { AuthService, AuthGuardService} from './auth/';
import { RequestService } from './request/request.service';

// SysBiz API
import { Sysbiz } from 'src/app/config/sysbiz';
import { SysbizModule } from './sysbiz/sysbiz.module';
import { SysbizSdiService } from './sysbiz/sysbiz-sdi.service';
import { SysbizAuthService } from './sysbiz/sysbiz-auth.service';
import { SysbizCustomerService } from './sysbiz/sysbiz-customer.service';
import { GotoStartpageComponent } from './goto-startpage/goto-startpage.component';
import { MultiTranslateHttpLoader } from 'ngx-translate-multi-http-loader';
import { BankingRedirectGuard } from './auth/banking-redirect.guard';

export function createTranslateLoader(http: HttpBackend) {
  return new MultiTranslateHttpLoader(http, [
    { prefix: './assets/base/i18n/', suffix: '.json' },
    { prefix: './assets/i18n/', suffix: '.json' }
  ]);
}

@NgModule({
  imports: [
    BrowserModule,
    CommonModule,
    HttpClientModule,
    // AppRoutingModule,
    NgbModule,
    SysbizModule.forRoot(Sysbiz),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpBackend]
      }
    })
  ],
  exports: [
    NgbModule,
    // AppRoutingModule
  ],
  declarations: [GotoStartpageComponent]
})
export class CoreModule {
  static forRoot(): ModuleWithProviders<CoreModule> {
    return {
      ngModule: CoreModule,
      providers: [
        AuthService, AuthGuardService, BankingRedirectGuard,
        TranslateService,
        TranslatePipe,
        RequestService,
        SysbizAuthService,
        SysbizCustomerService,
        SysbizSdiService,
        DecimalPipe,
        DatePipe
      ]
    };
  }
}

