import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { environment } from 'src/environments/environment';
import { Partner } from 'src/app/config/partner/_default';
import { AuthService, RequestService } from 'src/app/core';
import { Observable, from } from 'rxjs';
import { RequestError } from 'src/app/core/request';
import { FormGroup, FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  public redirect: boolean;
  private return: string = '';
  public showPassword: boolean = false;
  public errors = {
    credentials: null,
    validity: null
  };

  public backlink = Partner.backlink;
  
  public form: FormGroup;
  public formData = {
    email: null,
    password: null
  };
  public errorLoginMessage: string;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private authService: AuthService,
    private requests: RequestService
  ) {
    document.body.className = "bg-login";
  }

  ngOnInit() {
    // Get the query params
    this.route.queryParams
      .subscribe(params => this.return = params['return'] || '');

    this.form = new FormGroup({
      email: new FormControl(this.formData.email, [
        //Validators.email,
        Validators.required
      ]),
      password: new FormControl(this.formData.password, [
        Validators.required
      ])
    });
  }

  public async login(user: string, password: string) {
    this.errorLoginMessage = null;
    let call$ = from(this.authService.login(user, password));
    this.requests.addRequest('login', call$).subscribe(
      x => {
        this.router.navigateByUrl(this.return)
      },
      (err: RequestError) => 
      {
        if(err.url && err.url.indexOf("/passwordExpired")>=0){
          var url = `${err.url}?userName=${user}`;
          if(this.return){
            url += `&return=${this.return}`;
          }

          window.location.href = url;
        }               
        else{         
          this.errorLoginMessage = err.translatedMessage;
        }
      }
    );
  } 
  /**
   * Check if the login request is pending
   */
  public loginPending(): boolean {
    return this.requests.getRequest('login').isPending;
  }

  public get email() {
    return this.form.get('email');
  }

  public get password() {
    return this.form.get('password');
  }

}
