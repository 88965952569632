// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.nav-item-label {
  position: relative;
  display: inline-block;
}
.nav-item-label .badge {
  position: absolute;
  top: 0;
  right: 0;
  transform: translate(50%, -50%);
  font-size: 0.6em;
  padding: 0.25em 0.4em;
}

.statisticsIcon {
  margin-left: 2px;
}

.statistics {
  margin-left: 2px;
}

@media (max-width: 575px) {
  .navbar-collapse.show .nav-item-label {
    display: inline-block;
  }
  .navbar-collapse.show .nav-link-label {
    display: inline-block !important;
  }
  .navbar-collapse.show .nav-link {
    margin-top: 7px;
  }
  .navbar-toggler-icon {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255,255,255, 255)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
  }
}`, "",{"version":3,"sources":["webpack://./src/app/shared/components/navigation-main/navigation-main.component.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,qBAAA;AACF;AACE;EACE,kBAAA;EACA,MAAA;EACA,QAAA;EACA,+BAAA;EACA,gBAAA;EACA,qBAAA;AACJ;;AAEA;EACE,gBAAA;AACF;;AACA;EACE,gBAAA;AAEF;;AAAA;EAEE;IACE,qBAAA;EAEF;EAAA;IACE,gCAAA;EAEF;EAAA;IACE,eAAA;EAEF;EAAA;IACE,yQAAA;EAEF;AACF","sourcesContent":[".nav-item-label {\r\n  position: relative;\r\n  display: inline-block;\r\n\r\n  .badge {\r\n    position: absolute;\r\n    top: 0;\r\n    right: 0;\r\n    transform: translate(50%, -50%);\r\n    font-size: 0.6em;\r\n    padding: 0.25em 0.4em;\r\n  }\r\n} \r\n.statisticsIcon {\r\n  margin-left: 2px;\r\n}\r\n.statistics {\r\n  margin-left: 2px;\r\n}\r\n@media (max-width: 575px)\r\n{\r\n  .navbar-collapse.show .nav-item-label {\r\n    display: inline-block\r\n  }\r\n  .navbar-collapse.show .nav-link-label {\r\n    display: inline-block !important;\r\n  }\r\n  .navbar-collapse.show .nav-link {\r\n    margin-top: 7px;\r\n  }\r\n  .navbar-toggler-icon{\r\n    background-image: url(\"data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255,255,255, 255)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E\");\r\n  }\r\n}\r\n\r\n\r\n\r\n\r\n\r\n\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
