import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AuthService, IDtoGuid, PagedResultDto } from '@systems/base';
import { map, Observable } from 'rxjs';
import { ApplicationDto, WriteApplicationDto } from './application.service';
import { StateTypeDto } from './customer-state-type';
import { CustomerDto, CustomerSortingDto } from './customer.service';
import { BaseFilterDto, WriteBaseModelDto } from './models';
import { SortDirectionDto } from './sort-direction';
import { LicensedModuleDto } from './translation.service';
import { Option } from 'src/app/features/invoices/components/invoice/select/select.component';
import { RestApiClientAdapterService } from '../../sysbiz/rest-api-client-adapter.service';
import { CUSTOMERAPICLIENT_CONFIG } from '../../sysbiz/config-loader.service';
import { UserDto } from '../../sysbiz/sysbiz-auth.service';

@Injectable({
  providedIn: 'root'
})
export class PartnerService extends RestApiClientAdapterService<PartnerDto, DetailsPartnerDto, WritePartnerDto> {


  constructor(
    protected http: HttpClient,
    authService: AuthService,
    protected translationService: TranslateService
  ) {
    super(CUSTOMERAPICLIENT_CONFIG, http, authService, 'partners');
  }

  public listByFilters(
    sorting?: PartnerSortingDto,
    sortDirection?: SortDirectionDto,
    searchText?: string,
    state?: string,
    pageNumber?: number,
    pageSize?: number
  ): Observable<PagedResultDto<PartnerDto>> {

    let params = new HttpParams();

    if (sorting) {
      params = params.append('sorting', sorting as any);
    }
    if (sortDirection) {
      params = params.append('sortDirection', sortDirection as any);
    }
    if (searchText) {
      params = params.append('name', searchText as any);
    }
    if (state) {
      params = params.append('state', state as any);
    }
    if (pageNumber) {
      params = params.append('pageNumber', pageNumber as any);
    }
    if (pageSize) {
      params = params.append('pageSize', pageSize as any);
    }
    return this.http.get<PagedResultDto<PartnerDto>>(this.getUrl(`partners`),
      {
        responseType: 'json',
        observe: "response",
        headers: {
          authenticate: this.Token
        },
        params
      }).pipe(map((resp: any) => {
        this.updateTokenFromResponse(resp);
        return resp.body;
      }));
  }

  public listPartnersFromUser(
    userGuid: string,
    sorting?: PartnerSortingDto,
    sortDirection?: SortDirectionDto,
    searchText?: string,
    state?: StateTypeDto,
    pageNumber?: number,
    pageSize?: number
  ): Observable<PagedResultDto<PartnerDto>> {

    let params = new HttpParams();
    if (sorting) {
      params = params.append('sorting', sorting as any);
    }
    if (sortDirection) {
      params = params.append('sortDirection', sortDirection as any);
    }
    if (searchText) {
      params = params.append('name', searchText as any);
    }
    if (pageNumber) {
      params = params.append('pageNumber', pageNumber as any);
    }
    if (pageSize) {
      params = params.append('pageSize', pageSize as any);
    }
    if (state) {
      params = params.append('state', state as any);
    }
    if (userGuid) {
      params = params.append('userGuid', userGuid as any);
    }
    return this.http.get<PagedResultDto<PartnerDto>>(this.getUrl(`partners/getByUserGuid`),
      {
        responseType: 'json',
        headers: {
          authenticate: this.Token
        },
        observe: "response",
        params
      }).pipe(map((resp: any) => {
        this.updateTokenFromResponse(resp);
        return resp.body;
      }));
  }

  public listCustomersOfPartner(
    partnerGuid?: string,
    sorting?: CustomerSortingDto,
    sortDirection?: SortDirectionDto,
    searchText?: string,
    state?: StateTypeDto,
    pageNumber?: number,
    pageSize?: number
  ): Observable<PagedResultDto<CustomerDto>> {

    let params = new HttpParams();

    if (sorting) {
      params = params.append('sorting', sorting as any);
    }
    if (sortDirection) {
      params = params.append('sortDirection', sortDirection as any);
    }
    if (searchText) {
      params = params.append('name', searchText as any);
    }
    if (state) {
      params = params.append('state', state as any);
    }
    if (pageNumber) {
      params = params.append('pageNumber', pageNumber as any);
    }
    if (pageSize) {
      params = params.append('pageSize', pageSize as any);
    }

    return this.http.get<PagedResultDto<CustomerDto>>(this.getUrl(`partners/${partnerGuid}/customers`),
      {
        responseType: 'json',
        observe: "response",
        headers: {
          authenticate: this.Token
        },
        params
      }).pipe(map((resp: any) => {
        this.updateTokenFromResponse(resp);
        return resp.body;
      }));
  }

  public Update(guid: string, item: WritePartnerDto) {
    let params = new HttpParams();
    if (guid) {
      params = params.append('guid', guid as any);
    }
    return this.http.put<PartnerDto>(this.getUrl(`partners/${guid}`), item, {
      headers: {
        'authenticate': this.Token
      },
      observe: 'response',
      params: params,
      responseType: 'json',
    })
      .pipe(map(resp => {
        this.updateTokenFromResponse(resp);
        return resp.body!;
      }));
  }

  public getTranslationByType(enumeration: Array<any>, type: string, withKey: boolean = true): Array<Option> {
    let stati = new Array<Option>();
    enumeration.forEach((value) => {
      this.translationService.get(`${type}.types.${value}`).subscribe((res: string) => {
        stati.push({ value: value, description: (withKey ? value + ' - ' : '') + res });
      });
    });
    return stati;
  }

  public assignUserToPartner(users: Array<UserDto>, partnerGuid: string) {
    return this.http.post<UserDto>(this.getUrl(`partners/${partnerGuid}/users`), users, {
      headers: {
        'authenticate': this.Token
      },
      observe: 'response',
      responseType: 'json',
    })
      .pipe(map(resp => {
        this.updateTokenFromResponse(resp);
        return resp.body!;
      }));
  }
  public removePartnerUser(userGuid: string, partnerGuid: string) {
    return this.http.delete<boolean>(this.getUrl(`partners/${partnerGuid}/users/${userGuid}`), {
      headers: {
        'authenticate': this.Token
      },
      observe: 'response',
      responseType: 'json',
    })
      .pipe(map(resp => {
        this.updateTokenFromResponse(resp);
        return resp.body!;
      }));
  }

  public listPartnerLicenses(guid: string) {
    return this.http.get<Array<string>>(this.getUrl(`partners/${guid}/partnerLicenses`), {
      headers: {
        'authenticate': this.Token
      },
      observe: 'response'
    })
      .pipe(map(res => {
        this.updateTokenFromResponse(res);
        return res.body;
      }));
  }

  public updateLogo(guid: string, item: File) {
    const formData = new FormData();
    formData.append("logo", item);
    return this.http.put(this.getUrl(`partners/${guid}/logo`), formData, {
      headers: {
        'authenticate': this.Token
      },
      observe: 'response',
      responseType: 'json'
    })
      .pipe(map(res => {
        this.updateTokenFromResponse(res);
        return res.body;
      }));
  }

  public createLogo(guid: string, item: File) {
    const formData = new FormData();
    formData.append("logo", item);
    return this.http.post(this.getUrl(`partners/${guid}/logo`), formData, {
      headers: {
        'authenticate': this.Token
      },
      observe: 'response',
      responseType: 'json'
    })
      .pipe(map(res => {
        this.updateTokenFromResponse(res);
        return res.body;
      }));
  }

  public loadLogo(guid: string) {
    return this.http.get(this.getUrl(`partners/${guid}/logo`),
      {
        responseType: 'blob',
        headers: {
          authenticate: this.Token
        },
        observe: "response",
      }).pipe(map((resp: any) => {
        this.updateTokenFromResponse(resp);
        return resp.body;
      }));
  }

  public deleteLogo(guid: string) {
    return this.http.delete(this.getUrl(`partners/${guid}/logo`),
      {
        responseType: 'json',
        headers: {
          authenticate: this.Token
        },
        observe: "response",
      }).pipe(map((resp: any) => {
        this.updateTokenFromResponse(resp);
        return resp.body;
      }));
  }
}

export interface DetailsPartnerDto extends PartnerDto {
  licensedModules: DetailsPartnerLicensedModuleDto[];
}

export interface PartnerDto extends IDtoGuid, WritePartnerDto {
  hasLogo: boolean;
}

export interface WritePartnerDto extends WriteBaseModelDto {
  name?: string;
  vat?: string;
  taxNumber?: string;
  number?: string;
  startDate?: string;
  endDate?: string;
  state?: StateTypeDto;
  licensedModules?: WritePartnerLicensedModuleDto[];
  note?: string;
  mailFrom?: string;
  portalUrl?: string;
  apiUrl?: string;
  applications?: Array<ApplicationDto>;
}

export interface PartnerFilterDto extends BaseFilterDto {
  sorting?: PartnerSortingDto;
  sortDirection?: SortDirectionDto;
  state?: StateTypeDto;
  number?: string;
  name?: string;
  vat?: string;
  taxNumber?: string;
  pageNumber?: number;
  pageSize?: number;
}

export interface PartnerUserDto {
  partnerGuid: string,
  userGuid: string,
  username?: string
}

export interface DetailsPartnerLicensedModuleDto extends WritePartnerLicensedModuleDto {
  guid: string;
  partner: PartnerDto;
  licensedModule: LicensedModuleDto;
}

export interface WritePartnerLicensedModuleDto {
  partnerGuid: string;
  contractCompanyGuid?: string;
  contractTemplateGuidDE?: string;
  contractTemplateGuidIT?: string;
  licensedModuleId: number;
  usersecret?: string;
}

export enum PartnerSortingDto {
  guid = "Guid",
  name = "name",
  vat = "Vat",
  taxNumber = "TaxNumber",
  number = "number",
  startDate = "StartDate",
  endDate = "EndDate",
  state = "State",
  note = "Note",
  documentPackageSize = "DocumentPackageSize",
  mailFrom = "MailFrom",
  portalUrl = "PortalUrl",
  apiUrl = "ApiUrl",
}
