import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';

@Component({
  selector: 'icon',
  templateUrl: './icon.component.html',
  styleUrls: ['./icon.component.scss']
})
export class IconComponent implements OnChanges {
  @Input() trigger: boolean;
  @Input() active: string;
  @Input() inactive: string;

  public className: string;

  ngOnChanges(changes: SimpleChanges) {
    this.className = changes.trigger && changes.trigger.currentValue ? this.inactive : this.active;
  }

}
