import { Component } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { TranslateService } from "@ngx-translate/core";
import { BaseEditComponent, MonitoringService } from "@systems/base";
import { Subscription } from "rxjs";
import {
  NumberCompletenessCheckDto,
  NumberCompletenessCheckService,
  WriteNumberCompletenessCheckDto,
} from "src/app/core/sysbiz/number-completeness-check.service";

@Component({
  selector: "app-disable-number-completeness-check",
  templateUrl: "./disable-number-completeness-check.component.html",
  styleUrls: ["./disable-number-completeness-check.component.scss"],
})
export class DisableNumberCompletenessCheckComponent extends BaseEditComponent<
  NumberCompletenessCheckDto,
  WriteNumberCompletenessCheckDto,
  NumberCompletenessCheckService
> {

  public get dtoName(): string {
    return "NumberCompletenessCheck";
  }

  private deleteSubscription?: Subscription;

  constructor(
    service: NumberCompletenessCheckService,
    modal: NgbModal,
    monitoringService: MonitoringService,
    translationService: TranslateService
  ) {
    super(service, modal, monitoringService, translationService);
  }

  override ngOnDestroy(): void {
    if (this.deleteSubscription) {
      this.deleteSubscription.unsubscribe();

      this.deleteSubscription = undefined;
    }
  }

  public override save() {
    this.resetError();
    const dtoItem = this.item as NumberCompletenessCheckDto;

    if (this.deleteSubscription) {
      this.deleteSubscription.unsubscribe();
    }

    if (!this.isNew) {
      this.monitoringService.logEvent(`Disabling ${this.dtoName}`);
      this.updating = true;
      this.deleteSubscription = this.service.delete(dtoItem.guid).subscribe({
        next: (x) => {
          this.closeDialog.emit();
          this.saved = true;
          this.canEdit = false;
          this.updating = false;
        },
        error: (x) => {
          this.raiseError(x);
          this.canEdit = true;
          this.updating = false;
        },
      });
    }
  }

  protected generateDefault(): WriteNumberCompletenessCheckDto {
    return {} as any;
  }
}
