import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, CanActivateChild } from '@angular/router';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService implements CanActivate, CanActivateChild {

  constructor(
    private authService: AuthService
  ) { }


  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> | boolean {
    return this.validate(route, state);
  }

  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> | boolean {
    return this.validate(route, state);
  }

  /**
   * Checks whether a role is allowed to see the route
   * @param route ActivatedRouteSnapshot Route information provided by router
   */
  private validate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> | boolean {
    // Allow route if exceptions are specified
    // Potential security issue, since somebody could just guess the parameters and access the route
    // if (route.data.exceptions && route.data.exceptions.queryParams.every(val => route.queryParamMap.keys.includes(val)))
    // IE11 workaround, not covered by polyfills:
    if (route.data.userIsAGuest)
      return true;

    if (route.data.exceptions && route.data.exceptions.queryParams.every((val: string) => route.queryParamMap.keys.indexOf(val) >= 0)) {
      return true;
    }

    // No role restrictions → user authenticated?
    return this.authService.loggedIn().then(loggedIn => {
      if (loggedIn) {
        // User is authenticated

        if (route.data.roles) {
          // Route-roles specified → let RBAC do its magic
          return this.authService.rbac(route.data.roles).then(authorized => {
            if (authorized) {
              return true;
            } else {
              // User not authorized → reroute to login page
              this.authService.redirectToLogin(state.url);
              return false;
            }
          });
        } else {
          return true;
        }
      } else {
        // User not authenticated → reroute to login page
        this.authService.redirectToLogin(state.url);
        return false;
      }
    });
  }
}