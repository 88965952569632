import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AuthService, IDtoGuid, PagedResultDto } from '@systems/base';
import { map, Observable } from 'rxjs';
import { Option } from 'src/app/features/invoices/components/invoice/select/select.component';
import { CUSTOMERAPICLIENT_CONFIG } from '../../sysbiz/config-loader.service';
import { RestApiClientAdapterService } from '../../sysbiz/rest-api-client-adapter.service';
import { UserDto, WriteUserDto } from '../../sysbiz/sysbiz-auth.service';
import { ApplicationDto, WriteApplicationDto } from './application.service';
import { CompanyDto, CompanySortingDto } from './company.service';
import { StateTypeDto } from './customer-state-type';
import { LicensedModuleDefaultTypesDto } from './licensed-module-type';
import { BaseFilterDto, WriteBaseModelDto } from './models';
import { PartnerDto } from './partner.service';
import { SortDirectionDto } from './sort-direction';
import { LicensedModuleDto } from './translation.service';

@Injectable({
  providedIn: 'root'
})
export class CustomerService extends RestApiClientAdapterService<CustomerDto, DetailsCustomerDto, WriteCustomerDto> {

 

  constructor(
    authService: AuthService,
    protected http: HttpClient,
    protected translationService: TranslateService
  ) {
    super(CUSTOMERAPICLIENT_CONFIG, http, authService, 'customers');
  }


  public customerPackageSizes(): Observable<number[]>  {
    return this.http.get<number[]>(this.getUrl(`customers/validPackageSizes`),
      {
        responseType: 'json',
        headers: {
          authenticate: this.Token
        },
        observe: "response"        
      }).pipe(map((resp: any) => {
        this.updateTokenFromResponse(resp);
        return resp.body;
      }));    
  }
  /**
   * Get a list of the items
   *
   * @param pageNumber current page for the paging
   * @param pageSize size of the page for the paging
   */
  public listByFilters(
    sorting?: CustomerSortingDto,
    sortDirection?: SortDirectionDto,
    searchText?: string,
    partnerName?:string,
    state?: string,
    pageNumber?: number,
    pageSize?: number,
  ): Observable<PagedResultDto<CustomerDto>> {

    let params = new HttpParams();
    if (sorting) {
      params = params.append('sorting', sorting as any);
    }
    if (sortDirection) {
      params = params.append('sortDirection', sortDirection as any);
    }
    if (searchText) {
      params = params.append('name', searchText as any);
    }
    if (partnerName) {
      params = params.append('partnerName', partnerName as any);
    }
    if (state) {
      params = params.append('state', state as any);
    }
    if (pageNumber) {
      params = params.append('pageNumber', pageNumber as any);
    }
    if (pageSize) {
      params = params.append('pageSize', pageSize as any);
    }

    return this.http.get<PagedResultDto<CustomerDto>>(this.getUrl(`customers`),
      {
        responseType: 'json',
        headers: {
          authenticate: this.Token
        },
        observe: "response",
        params
      }).pipe(map((resp: any) => {
        this.updateTokenFromResponse(resp);
        return resp.body;
      }));
  }

  /**
   * Get a list of the items
   *
   * @param pageNumber current page for the paging
   * @param pageSize size of the page for the paging
   */
  public listCompaniesOfCustomer(
    sorting?: CompanySortingDto,
    sortDirection?: SortDirectionDto,
    searchText?: string,
    customerGuid?: string,
    pageNumber?: number,
    pageSize?: number
  ): Observable<PagedResultDto<CompanyDto>> {

    let params = new HttpParams();

    if (sorting) {
      params = params.append('sorting', sorting as any);
    }
    if (sortDirection) {
      params = params.append('sortDirection', sortDirection as any);
    }
    if (searchText) {
      params = params.append('searchName', searchText as any);
    }
    if (pageNumber) {
      params = params.append('pageNumber', pageNumber as any);
    }
    if (pageSize) {
      params = params.append('pageSize', pageSize as any);
    }
    return this.http.get<PagedResultDto<CompanyDto>>(this.getUrl(`customers/${customerGuid}/companies`),
      {
        responseType: 'json',
        headers: {
          authenticate: this.Token
        },
        observe: "response",
        params
      }).pipe(map((resp: any) => {
        this.updateTokenFromResponse(resp);
        return resp.body;
      }));
  }

  public listCustomersFromUser(
    userGuid: string,
    sorting?: CustomerSortingDto,
    sortDirection?: SortDirectionDto,
    searchText?: string,
    state?: StateTypeDto,
    pageNumber?: number,
    pageSize?: number
  ): Observable<PagedResultDto<CustomerDto>> {

    let params = new HttpParams();

    if (sorting) {
      params = params.append('sorting', sorting as any);
    }
    if (sortDirection) {
      params = params.append('sortDirection', sortDirection as any);
    }
    if (searchText) {
      params = params.append('name', searchText as any);
    }
    if (pageNumber) {
      params = params.append('pageNumber', pageNumber as any);
    }
    if (pageSize) {
      params = params.append('pageSize', pageSize as any);
    }
    if (state) {
      params = params.append('state', state as any);
    }
    if (userGuid) {
      params = params.append('userGuid', userGuid as any);
    }
    return this.http.get<PagedResultDto<CustomerDto>>(this.getUrl(`customers/getByUserGuid`),
      {
        responseType: 'json',
        headers: {
          authenticate: this.Token
        },
        observe: "response",
        params
      }).pipe(map((resp: any) => {
        this.updateTokenFromResponse(resp);
        return resp.body;
      }));
  }


  public getTranslationByType(enumeration: Array<any>, type: string, withKey: boolean = true): Array<Option> {
    let stati = new Array<Option>();
    enumeration.forEach((value) => {
      this.translationService.get(`${type}.types.${value}`).subscribe((res: string) => {
        stati.push({ value: value, description: (withKey ? value + ' - ' : '') + res });
      });
    });
    return stati;
  }
  public assignUserToCustomer(users: Array<WriteUserDto>, customerGuid: string  ){
    return this.http.post<UserDto>(this.getUrl(`customers/${customerGuid}/users`), users, {
      headers: {
        'authenticate': this.Token
      },
      observe: 'response',
      responseType: 'json',
    })
      .pipe(map(resp => {
        this.updateTokenFromResponse(resp);
        return resp.body!;
      }));
  }
  public removeCustomerUser(userGuid: string, customerGuid: string ){
    return this.http.delete<boolean>(this.getUrl(`customers/${customerGuid}/users/${userGuid}`), {
      headers: {
        'authenticate': this.Token
      },
      observe: 'response',
      responseType: 'json',
    })
      .pipe(map(resp => {
        this.updateTokenFromResponse(resp);
        return resp.body!;
      }));
  }
  public listCustomerLicenses(guid: string){
    return this.http.get<Array<string>>(this.getUrl(`customers/${guid}/customerLicenses`), { headers: { 'authenticate': this.Token }, observe: 'response'})
    .pipe(map(res => {
      this.updateTokenFromResponse(res);
      return res.body;
    }));
  }

  public listCustomerLicenseIdsByUser(guid: string){
    return this.http.get<Array<string>>(this.getUrl(`customers/licenses/${guid}`), { headers: { 'authenticate': this.Token }, observe: 'response'})
    .pipe(map(res => {
      this.updateTokenFromResponse(res);
      return res.body;
    }));
  }
  
  public isPartnerAdmin(customerGuid: string) {
    return this.http.get<boolean>(this.getUrl(`customers/isPartnerAdmin/${customerGuid}`),
      {
        headers: { 'authenticate': this.Token },
        observe: 'response'
      })
      .pipe(map(res => {
        this.updateTokenFromResponse(res);
        return res.body;
      }));
  }
}

export interface DetailsCustomerDto extends CustomerDto {
  licensedModules: Array<LicensedModuleDto>;
}

export interface CustomerDto extends IDtoGuid, WriteCustomerDto {
}

export interface WriteCustomerDto extends WriteBaseModelDto {
  name: string;
  vat?: string;
  taxNumber?: string;
  number?: string;
  startDate: string;
  endDate?: string;
  state: StateTypeDto;
  licensedModuleIds: number[];
  note?: string;
  documentPackageSize?: number;
  bankAccountsAmount?: number;
  signerDocumentPackageSize?: number;
  partnerGuid: string;
  partner?: PartnerDto;
  applications?: Array<ApplicationDto>;
  blockWhenPackageExceeded: boolean;
}

export interface CustomerFilterDto extends BaseFilterDto {
  sorting?: CustomerSortingDto;
  sortDirection?: SortDirectionDto;
  state?: StateTypeDto;
  licensedModule?: LicensedModuleDefaultTypesDto;
  number?: string;
  name?: string;
  vat?: string;
  taxNumber?: string;
  pageNumber?: number;
  pageSize?: number;
}

export interface CustomerUserDto{
  customerGuid: string,
  userGuid: string,
  username?: string
}

export enum CustomerSortingDto {
  guid = "Guid",
  name = "Name",
  vat = "Vat",
  taxNumber = "TaxNumber",
  number = "Number",
  startDate = "StartDate",
  endDate = "EndDate",
  state = "State",
  note = "Note",
  documentPackageSize = "DocumentPackageSize",
  partnerGuid = "PartnerGuid"
}
