import { Directive } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { BaseEditComponent, BasePageComponent, IDtoGuid, IEditApi, MonitoringService } from '@systems/base';
import { HttpErrorResponse } from '@angular/common/http';
import { AuthService } from '../core';

@Directive()
export abstract class BaseEditAdapterComponent<
  Dto extends WriteDto & IDtoGuid,
  WriteDto, 
  Service extends IEditApi<WriteDto,Dto>>
  extends BaseEditComponent<Dto,WriteDto,Service>{

  constructor(
    protected service: Service,
    modal: NgbModal,
    monitoringService: MonitoringService,
    translationService: TranslateService,
    protected authService: AuthService) {
      super(service, modal, monitoringService, translationService);
    }

  override async raiseError(message: any) {
    if ((message.status == 401) || (message instanceof HttpErrorResponse && (message.error.defaultMessage === 'Token is expired') || (message.error.title === 'Unauthorized')))
      this.authService.logout();

    super.raiseError(message);
  }
}