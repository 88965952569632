import { EventEmitter, Injectable, ViewChild } from '@angular/core';
import { Observable, of, Subject, Subscription } from 'rxjs';
import { NavigationMainComponent } from '../navigation-main/navigation-main.component';
import { NavigationSidebarComponent } from './navigation-sidebar.component';

@Injectable({
  providedIn: 'root'
})
export class SidebarToggleService {
  
  public isMenuCollapsed: boolean = false;
  public subject = new Subject<any>();

  constructor() { }

  public toggle(){
    this.isMenuCollapsed = !this.isMenuCollapsed;
    this.subject.next(this.isMenuCollapsed);
  }
  public GetValue(): Observable<any> {
    return this.subject.asObservable();
  }
}
